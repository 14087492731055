import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import "./styles/storeMap.css";
import "./scssStyles/map.scss";
import {
    GoogleMap,
    DistanceMatrixService,
    useJsApiLoader,
} from "@react-google-maps/api";
import axios from "axios";
import StoreMarker from "./StoreMarker";

export default function Stores() {
    const [markers, setMarkers] = useState([]);
    const [postcode, setPostcode] = useState("");
    const [sites, setSites] = useState([]);
    const [dataPoints, setDataPoints] = useState([]);
    const [checkDistance, setCheckDistance] = useState(false);
    const [showReset, setShowReset] = useState(false);
    const [search, setSearch] = useState(false);
    const [current, setCurrent] = useState("");

    useEffect(() => {
        axios
            .get("https://maps.rt-itservices.co.uk?site=Tubbees")
            .then((res) => {
                setMarkers(res.data.sites);
                // console.log(res.data.sites)
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const containerStyle = {
        width: "100%",
        height: "600px",
    };

    const center2 = {
        lat: 55.90072430176191,
        lng: -4.230517394800327,
    };

    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: "AIzaSyCA17XufYl3oox1qRT6aXvvM8JqmyvGC1I",
    });

    const searchPostcode = async () => {
        setShowReset(true);
        var data = { postcode: postcode };
        await axios
            .post("https://maps.rt-itservices.co.uk/postcode", data)
            .then((res) => {
                if (!res.data.Found) {
                    alert("Postcode Not Found. Please Enter A Valid Postcode.");
                    setPostcode("");
                } else {
                    setDataPoints(res.data.points);
                    sitePoints();
                    setCheckDistance(true);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    function sitePoints() {
        var data = [];
        for (let i = 0; i < markers.length; i++) {
            data.push({
                lat: markers[i].Address.latitude,
                lng: markers[i].Address.longitude,
            });
        }
        setSites(data);
    }

    const viewDistance = async (point) => {
        var data = markers;
        try {
            for (let i = 0; i < data.length; i++) {
                data[i].Distance = point.rows[0].elements[i].distance;
            }
            const sortData = data.sort(compare);
            const a = markers.filter((site) => site.name === sortData[0].name);
            setMarkers(a);
            setSearch(true);
            setCheckDistance(false);
        } catch (err) {
            console.log(err);
        }
    };

    function compare(a, b) {
        if (a.Distance.value < b.Distance.value) {
            return -1;
        }
        if (a.Distance.value > b.Distance.value) {
            return 1;
        }
        return 0;
    }

    const reset = () => {
        axios
            .get("https://maps.rt-itservices.co.uk?site=Tubbees")
            .then((res) => {
                setMarkers(res.data.sites);
                console.log(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
        setPostcode("");
        setShowReset(false);
        setSearch(false);
    };
    const submit = (event) => {
        if (event.key === "Enter") {
            searchPostcode();
        }
    };

    const closeWindow = (name) => {
        setCurrent(name);
        // axios.get("https://maps.rt-itservices.co.uk?site=Tubbees").then((res) => {
        //     setMarkers(res.data.sites)
        //     const a = res.data.sites.filter(site => site.name === name)
        //     console.log(res.data)
        // }).catch((err) => {
        //     console.log(err)
        // })
    };

    return isLoaded ? (
        <div id="stores" style={{ zIndex: "100", backgroundColor: "#fff" }}>
            <h1 style={{ zIndex: "110" }}>Our Stores</h1>
            <div className="searchMap">
                <input
                    style={{ zIndex: "100" }}
                    type="text"
                    onKeyDown={submit}
                    onChange={(e) => {
                        setPostcode(e.target.value);
                    }}
                    value={postcode}
                />
                <button onClick={searchPostcode}>
                    <SearchIcon sx={{ color: "#fff" }} className="searchIcon" />
                </button>
            </div>
            {checkDistance ? (
                <DistanceMatrixService
                    options={{
                        destinations: sites,
                        origins: [dataPoints],
                        travelMode: "DRIVING",
                    }}
                    callback={(response) => {
                        viewDistance(response);
                    }}
                />
            ) : (
                <></>
            )}
            <div className="wave2 wave2--top"></div>
            <GoogleMap
                id="google-map"
                mapContainerStyle={containerStyle}
                zoom={11}
                center={center2}
                options={{ streetViewControl: false }}
            >
                {markers.map(function (marker, id) {
                    var times;
                    marker.BusinessHours.Mon_Thurs
                        ? (times = "mon_thurs")
                        : marker.BusinessHours.Sun_Thurs
                        ? (times = "sun_thurs")
                        : marker.BusinessHours.Mon_Sat
                        ? (times = "mon_sat")
                        : (times = "everyday");
                    return (
                        <StoreMarker
                            currentName={current}
                            windowClosed={closeWindow}
                            resetMap={reset}
                            showMark={search}
                            key={id}
                            big={true}
                            dessert={marker.utilities.DessertLab}
                            slush={marker.utilities.Slush}
                            name={marker.name}
                            marker={marker.Address}
                            contact={marker.Contact}
                            hours={marker.BusinessHours}
                            opening={times}
                            utilities={marker.utilities}
                            comingSoon={marker.comingSoon}
                        />
                    );
                })}
            </GoogleMap>
            <div className="wave2"></div>
            <br />
        </div>
    ) : (
        <></>
    );
}
