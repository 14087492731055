import React from 'react'
import './scssStyles/slider2.scss'
import Des1 from '../images/slider1/one.webp'
import Des2 from '../images/slider1/two.webp'
import Des3 from '../images/slider1/three.webp'
import Des4 from '../images/slider1/four.webp'
import Des5 from '../images/slider1/five.webp'
import Des6 from '../images/slider1/six.webp'
import Des7 from '../images/slider1/seven.webp'
import Des8 from '../images/slider1/eight.webp'
import Des9 from '../images/slider1/nine.webp'
import Des10 from '../images/slider1/ten.webp'
import Des11 from '../images/slider1/eleven.webp'
import Des12 from '../images/slider1/twelve.webp'
import Des13 from '../images/slider1/thirteen.webp'
import Des14 from '../images/slider1/fourteen.webp'
import Des15 from '../images/slider1/fifteen.webp'
import Des16 from '../images/slider1/sixteen.webp'
import Des17 from '../images/slider1/seventeen.webp'
import Des18 from '../images/slider1/eighteen.webp'
import Des19 from '../images/slider1/nineteen.webp'


export default function Slider() {
  return (
    <div className="slider">
	<div className="slide-track">
		<div className="slide">
			<img src={Des1}  alt="Creepes" />
		</div>
		<div className="slide">
			<img src={Des3}  alt="Strawberry milkshake" />
		</div>
		<div className="slide">
			<img src={Des15}  alt="Pancake Stack" />
		</div>
		<div className="slide">
			<img src={Des6}  alt="Bueno Shake" />
		</div>
		<div className="slide">
			<img src={Des19}  alt="Crepes" />
		</div>
		<div className="slide">
			<img src={Des9}  alt="Caramel milkshake" />
		</div>
		<div className="slide">
			<img src={Des4}  alt="Oreo Shake" />
		</div>
		<div className="slide">
			<img src={Des13}  alt="Pancake Stack" />
		</div>
		<div className="slide">
			<img src={Des10}  alt="Milkshake" />
		</div>
		<div className="slide">
			<img src={Des18}  alt="Fudge puppies" />
		</div>
		<div className="slide">
			<img src={Des8}  alt="Milkshake" />
		</div>
		<div className="slide">
			<img src={Des16}  alt="Pancake Stack" />
		</div>
		<div className="slide">
			<img src={Des12}  alt="Milkshake" />
		</div>
		<div className="slide">
			<img src={Des2}  alt="Crepes" />
		</div>
		<div className="slide">
			<img src={Des17}  alt="Pancake" />
		</div>
		<div className="slide">
			<img src={Des11}  alt="Milkshake" />
		</div>
		<div className="slide">
			<img src={Des14}  alt="Churros" />
		</div>
		<div className="slide">
			<img src={Des7}  alt="Bueno milkshake" />
		</div>
	</div>
</div>
  )
}
