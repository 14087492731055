import React from "react";
import "./styles/tubbeesCarousel.css";
import dumbarton from "../images/carousel/dumbarton_rd_slushee.webp";
import autoport from "../images/carousel/autoport_slushee.webp";
import strathclyde from "../images/carousel/strathclyde_slush.webp";
import crowwood from "../images/carousel/crowwood_slushee.webp";

export default function Carousel2() {
    function shiftLeft() {
        const boxes = document.querySelectorAll(".box");
        const tmpNode = boxes[0];
        var pink = boxes[0].classList.contains("boxP");
        boxes[0].className = "box move-out-from-left";
        setTimeout(function () {
            if (pink) {
                if (boxes.length > 5) {
                    tmpNode.classList.add("box--hide");
                    boxes[5].className = "box boxP move-to-position5-from-left";
                }
                boxes[1].className = "box boxB move-to-position1-from-left";
                boxes[2].className = "box boxP move-to-position2-from-left";
                boxes[3].className = "box boxB move-to-position3-from-left";
                boxes[4].className = "box boxP move-to-position4-from-left";
            } else {
                if (boxes.length > 5) {
                    tmpNode.classList.add("box--hide");
                    boxes[5].className = "box boxB move-to-position5-from-left";
                }
                boxes[1].className = "box boxP move-to-position1-from-left";
                boxes[2].className = "box boxB move-to-position2-from-left";
                boxes[3].className = "box boxP move-to-position3-from-left";
                boxes[4].className = "box boxB move-to-position4-from-left";
            }
            boxes[0].remove();
            document.querySelector(".cards__container").appendChild(tmpNode);
        }, 200);
    }
    function shiftRight() {
        const boxes = document.querySelectorAll(".box");
        boxes[4].className = "box move-out-from-right";
        setTimeout(function () {
            const noOfCards = boxes.length;
            if (noOfCards > 4) {
                boxes[4].className = "box box--hide";
            }
            const tmpNode = boxes[noOfCards - 1];
            tmpNode.classList.remove("box--hide");
            boxes[noOfCards - 1].remove();
            let parentObj = document.querySelector(".cards__container");
            parentObj.insertBefore(tmpNode, parentObj.firstChild);
            console.log(tmpNode.classList);
            if (boxes[2].classList.contains("boxP")) {
                tmpNode.className = "box boxB move-to-position1-from-right";
                boxes[0].className = "box boxP move-to-position2-from-right";
                boxes[1].className = "box boxB move-to-position3-from-right";
                boxes[2].className = "box boxP move-to-position4-from-right";
                boxes[3].className = "box boxB move-to-position5-from-right";
            } else {
                tmpNode.className = "box boxP move-to-position1-from-right";
                boxes[0].className = "box boxB move-to-position2-from-right";
                boxes[1].className = "box boxP move-to-position3-from-right";
                boxes[2].className = "box boxB move-to-position4-from-right";
                boxes[3].className = "box boxP move-to-position5-from-right";
            }
        }, 200);
    }

    return (
        <div className="container2">
            <div className="buttonsCarousel">
                <svg
                    id="btnL"
                    viewBox="0 0 200 200"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill="#d87093"
                        d="M55.8,-60.5C70.6,-54,79.7,-34.8,75.4,-19.2C71,-3.6,53.2,8.4,42.5,22.2C31.8,36,28.1,51.6,16.7,63.3C5.3,75,-13.9,82.9,-29.1,78.1C-44.3,73.4,-55.5,55.9,-59.9,39C-64.4,22.2,-62,5.8,-60.7,-12.2C-59.3,-30.3,-58.9,-50,-49,-57.4C-39,-64.7,-19.5,-59.7,0.5,-60.2C20.5,-60.8,41,-67,55.8,-60.5Z"
                        transform="translate(100 100)"
                    />
                </svg>
                <span className="btnSpanL" onClick={() => shiftLeft()}>
                    &lt;
                </span>
                <svg id="btnR" viewBox="0 0 200 200">
                    <path
                        fill="#D87093"
                        d="M29,-36.4C36.7,-28.1,41.5,-18.1,50.4,-3.5C59.4,11,72.5,30.2,70.2,46.5C67.9,62.8,50.3,76.1,33.7,74.2C17.1,72.3,1.7,55,-17.7,48.2C-37.1,41.3,-60.5,44.9,-62.4,37.8C-64.3,30.8,-44.8,13.2,-33.9,1.4C-23,-10.4,-20.8,-16.3,-16.6,-25.2C-12.4,-34.2,-6.2,-46.2,2.2,-48.9C10.6,-51.5,21.3,-44.8,29,-36.4Z"
                        transform="translate(100 100)"
                    />
                </svg>
                <span className="btnSpanR" onClick={() => shiftRight()}>
                    &gt;
                </span>
            </div>
            <div className="cards-wrapper">
                <ul className="cards__container">
                    <li className="box boxP">
                        <img
                            src={strathclyde}
                            style={{
                                padding: "10px",
                                border: "1px solid white",
                            }}
                            alt="Strathclyde slush"
                        />
                    </li>
                    <li className="box boxB">
                        <img
                            src={autoport}
                            style={{
                                padding: "10px",
                                border: "1px solid white",
                            }}
                            alt="Autoport Slush"
                        />
                    </li>
                    <li className="box boxP">
                        <img
                            src={dumbarton}
                            style={{
                                padding: "10px",
                                border: "1px solid white",
                            }}
                            alt="Dumbarton Rd Slush"
                        />
                    </li>
                    <li className="box boxB">
                        <img
                            src={crowwood}
                            style={{
                                padding: "10px",
                                border: "1px solid white",
                            }}
                            alt="Crowwood Slush"
                        />
                    </li>
                    <li className="box boxP">
                        <img
                            src={strathclyde}
                            style={{
                                padding: "10px",
                                border: "1px solid white",
                            }}
                            alt="Strathclyde"
                        />
                    </li>
                    <li className="box box--hide boxB">
                        <img
                            src={autoport}
                            style={{
                                padding: "10px",
                                border: "1px solid white",
                            }}
                            alt="Autoport"
                        />
                    </li>
                    <li className="box box--hide boxP">
                        <img
                            src={dumbarton}
                            style={{
                                padding: "10px",
                                border: "1px solid white",
                            }}
                            alt="Dunbarton Rd"
                        />
                    </li>
                </ul>
            </div>
            <div></div>
        </div>
    );
}
