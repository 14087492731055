import "../styles/story.css";
import slush from '../../images/icon/slushCupWhite.webp'


export default function Twentyone() {
  return (
    <div className="containerStory">
      <div className="storyHeading">
        <h2>2021</h2>
        <img src={slush} alt="slush cup"/>
        <img src={slush} alt="slush cup"/>
      </div>
      <p>We expanded, opening 3 new stores: Strathclyde, Scotstoun &amp; Crowwood.<br/><span>With up to 96 flavours at Strathclyde.</span></p>
    </div>
  );
}
