import iceCream from "../images/icon/Cone.svg";
import Slush from "../images/icon/Slush.svg";
import Muffin from "../images/icon/Cupcake.svg";
import Pancakes from "../images/icon/Pancake.svg";

// import './styles/Home.css'

export default function IconBar() {
  return (
    <section className="IconRow">
      <img src={Slush} alt="slushee" className="shake-slow" />
      <img src={iceCream} alt="ice cream" className="shake-slow" />
      <img src={Muffin} alt="Muffin" className="shake-slow" />
      <img src={Pancakes} alt="Pancakes" className="shake-slow" />
      <img src={Slush} alt="slushee" className="shake-slow" />
      <img src={iceCream} alt="ice cream" className="shake-slow" />
      <img src={Muffin} alt="Muffin" className="shake-slow" />
      <img src={Pancakes} alt="Pancakes" className="shake-slow" />
      <img src={Slush} alt="slushee" className="shake-slow" />
      <img src={iceCream} alt="ice cream" className="shake-slow" />
      <img src={Muffin} alt="Muffin" className="shake-slow" />
      <img src={Pancakes} alt="Pancakes" className="shake-slow" />
    </section>
  );
}
