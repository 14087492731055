import "../styles/story.css";
import slush from '../../images/icon/slushCupWhite.webp'

export default function Eighteen() {
  return (
    <div className="containerStory">
      <div className="storyHeading">
        <h2>2018</h2>
        <img src={slush} alt="slush cup"/>
        <img src={slush} alt="slush cup"/>
      </div>
      <p>
      Our journey began in 2018 with our Ibrox store. We introduced Glasgow to a variety of slush flavours.
        <br />
        <span>Starting with 30 tasty flavours.</span>
      </p>
    </div>
  );
}
