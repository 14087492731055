import "../styles/nitrolab.css";
import "../scssStyles/nitrolab.scss";

export default function Nitrolab() {
  return (
    <section className="nitroLab">
      <h1>NITRO LAB</h1>
      {/* <div className="test">
          <p>Choose your base</p>
          <div className="bases">
              <div className="nitro">
                  <p>Ic</p>
                  <span>Ice Cream</span>
              </div>
              <div className="nitro">
                  <p>Yo</p>
                  <span>Yogurt</span>
              </div>
              <div className="nitro">
                  <p>V</p>
                  <span>Vegan</span>
              </div>
          </div>
        </div> */}

      <div className="labRow">
        <div className="unit">
          <div className="type-number">
            <span className="number right">Base</span>
          </div>
          <span className="letter">IC</span>
          <span className="name">Ice Cream</span>
        </div>

        <div className="unit">
          <div className="type-number">
            <span className="number right">Base</span>
          </div>
          <span className="letter">Yo</span>
          <span className="name">Yogurt</span>
        </div>

        <div className="unit">
          <div className="type-number">
            <span className="number right">Base</span>
          </div>
          <span className="letter">V</span>
          <span className="name">Vegan</span>
        </div>
      </div>

      <div className="columnsLab">
        <div className="labColumn">
          <div className="unit">
            <div className="type-number">
              <span className="number right">Flavour</span>
            </div>
            <span className="letter">Mc</span>
            <span className="name">Milk Choc</span>
          </div>

          <div className="unit">
            <div className="type-number">
              <span className="number right">Flavour</span>
            </div>
            <span className="letter">Wc</span>
            <span className="name">White Choc</span>
          </div>

          <div className="unit">
            <div className="type-number">
              <span className="number right">Flavour</span>
            </div>
            <span className="letter">C</span>
            <span className="name">Chai</span>
          </div>

          <div className="unit">
            <div className="type-number">
              <span className="number right">Flavour</span>
            </div>
            <span className="letter">Nu</span>
            <span className="name">Nutella</span>
          </div>

          <div className="unit">
            <div className="type-number">
              <span className="number right">Flavour</span>
            </div>
            <span className="letter">Mn</span>
            <span className="name">Macadamia Nuts</span>
          </div>

          <div className="unit">
            <div className="type-number">
              <span className="number right">Flavour</span>
            </div>
            <span className="letter">M</span>
            <span className="name">Mango</span>
          </div>

          <div className="unit">
            <div className="type-number">
              <span className="number right">Flavour</span>
            </div>
            <span className="letter">Pi</span>
            <span className="name">Pistachio</span>
          </div>
          <div className="unit">
            <div className="type-number">
              <span className="number right">Flavour</span>
            </div>
            <span className="letter">Ma</span>
            <span className="name">Maple Spice</span>
          </div>
        </div>
        <div className="labColumn">
        <div className="unit">
            <div className="type-number">
              <span className="number right">Flavour</span>
            </div>
            <span className="letter">Ca</span>
            <span className="name">Caramel</span>
          </div>
          <div className="unit">
            <div className="type-number">
              <span className="number right">Flavour</span>
            </div>
            <span className="letter">Ap</span>
            <span className="name">Apple Pie</span>
          </div>
          <div className="unit">
            <div className="type-number">
              <span className="number right">Flavour</span>
            </div>
            <span className="letter">Bs</span>
            <span className="name">Butterscotch</span>
          </div>
          <div className="unit">
            <div className="type-number">
              <span className="number right">Flavour</span>
            </div>
            <span className="letter">Cc</span>
            <span className="name">Cotton Candy</span>
          </div>
          <div className="unit">
            <div className="type-number">
              <span className="number right">Flavour</span>
            </div>
            <span className="letter">Va</span>
            <span className="name">Vanilla</span>
          </div>
          <div className="unit">
            <div className="type-number">
              <span className="number right">Flavour</span>
            </div>
            <span className="letter">Cf</span>
            <span className="name">Coffee</span>
          </div>
          <div className="unit">
            <div className="type-number">
              <span className="number right">Flavour</span>
            </div>
            <span className="letter">Co</span>
            <span className="name">Coconut</span>
          </div>
        </div>

        <div className="labColumn">
        <div className="unit">
            <div className="type-number">
              <span className="number right">Flavour</span>
            </div>
            <span className="letter">Ci</span>
            <span className="name">Cinnamon</span>
          </div>
          <div className="unit">
            <div className="type-number">
              <span className="number right">Flavour</span>
            </div>
            <span className="letter">Vi</span>
            <span className="name">Violet</span>
          </div>
          <div className="unit">
            <div className="type-number">
              <span className="number right">Flavour</span>
            </div>
            <span className="letter">Bu</span>
            <span className="name">Bubble Gum</span>
          </div>
          <div className="unit">
            <div className="type-number">
              <span className="number right">Flavour</span>
            </div>
            <span className="letter">Ws</span>
            <span className="name">Wild Strawberry</span>
          </div>
          <div className="unit">
            <div className="type-number">
              <span className="number right">Flavour</span>
            </div>
            <span className="letter">Sc</span>
            <span className="name">Salted Caramel</span>
          </div>
          <div className="unit">
            <div className="type-number">
              <span className="number right">Flavour</span>
            </div>
            <span className="letter">Do</span>
            <span className="name">Donut</span>
          </div>
        </div>
        <div className="labColumn">
        <div className="unit">
            <div className="type-number">
              <span className="number right">Flavour</span>
            </div>
            <span className="letter">H</span>
            <span className="name">Honey</span>
          </div>
          <div className="unit">
            <div className="type-number">
              <span className="number right">Flavour</span>
            </div>
            <span className="letter">B</span>
            <span className="name">Banana</span>
          </div>
          <div className="unit">
            <div className="type-number">
              <span className="number right">Flavour</span>
            </div>
            <span className="letter">R</span>
            <span className="name">Rose</span>
          </div>
          <div className="unit">
            <div className="type-number">
              <span className="number right">Flavour</span>
            </div>
            <span className="letter">L</span>
            <span className="name">Lotus</span>
          </div>
          <div className="unit">
            <div className="type-number">
              <span className="number right">Flavour</span>
            </div>
            <span className="letter">Vc</span>
            <span className="name">Vegan Chocolate</span>
          </div>
        </div>
        <div className="labColumn">
        <div className="unit">
            <div className="type-number">
              <span className="number right">Flavour</span>
            </div>
            <span className="letter">Bl</span>
            <span className="name">Blood Orange</span>
          </div>
          <div className="unit">
            <div className="type-number">
              <span className="number right">Flavour</span>
            </div>
            <span className="letter">Ru</span>
            <span className="name">Caribbean Rum</span>
          </div>
          <div className="unit">
            <div className="type-number">
              <span className="number right">Flavour</span>
            </div>
            <span className="letter">Gm</span>
            <span className="name">Green Mint</span>
          </div>
        </div>
      </div>
    </section>
  );
}
