import "../scssStyles/menu.scss";
import { useState, useEffect } from "react";
import axios from 'axios'
import MenuItem from "../MenuItem";
import '../styles/menu1.css'

export default function Category(props) {
  const [products, setProducts] = useState([]);
  const [stores, setStores] = useState([]);

  useEffect(() => {
    axios.get(`https://nitrous.rt-itservices.co.uk/tubbeesSite/menu?category=${props.category}`).then((res) => {
      setProducts(res.data)
    }).catch((err) => {
      console.log(err)
    })
    axios.get("https://nitrous.rt-itservices.co.uk/tubbeesSite/stores").then((res) => {
      setStores(res.data)
    }).catch((err) => {
      console.log(err)
    })
  }, [])

  return (
    <section >
      <div className="storeKey">
        {stores ?
          stores.map(function (d, i) {
            return (
              <div className="storeContainer">
                <span className="keyIcon" style={{backgroundColor: `${d.colour}`}}>==</span>
                <span className="storeName">{d.name}</span>
              </div>
            )
          }) :
          <></>
        }
      </div>
      <br/>
      <div className="menu">
        {products.map(function (d, i) {
          return (
            <MenuItem key={i} name={d.name} img={d.img} description={d.description} calories={d.calories} percentage={d.percentage} allergens={d.allergens} sites={d.sites} />
          )
        })}
      </div>
    </section>
  );
}
