import "./styles/nav.css";
import { useNavigate } from "react-router-dom";
import logo from "../images/Round-logo.webp";
// import logo from "../images/tubbees_xmas.png";
import MenuIcon from "../images/menuIcon.webp";
import React, { useState, useEffect } from "react";

export default function Nav() {
  const navigate = useNavigate();
  const [navigation, setNavigation] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [showDrop, setShowDrop] = useState(false);

  useEffect(() => {
    if (
      window.location.pathname === "/menu" ||
      window.location.pathname === "/slushees" ||
      window.location.pathname === "/apply" ||
      window.location.pathname === "/franchise" ||
      window.location.pathname === "/boba-tea"
    ) {
      setNavigation(true);
    }
  }, []);

  const handleMenu = () => {
    if (mobileMenu === false) {
      setMobileMenu(true);
    } else {
      setMobileMenu(false);
    }
  };

  return (
    <nav>
      <div className="navWrapper">
        {navigation ? (
          <>
            <div className="leftNav">
              <h6>
                <a
                  onClick={() => {
                    document.title = "Tubbees | Slush";
                  }}
                  href="/#slush"
                >
                  Slushees
                </a>
              </h6>
              <div
                className="dropdown"
              >
                <h6>
                  <a href="https://nitro-tubbees.s3.eu-west-2.amazonaws.com/TUBBEES+MENU+2024.pdf?utm_source=WEBSITE&utm_campaign=MENU">Menu</a>
                </h6>
                <div className="dropdown-content">
                  <h6 onClick={() => navigate("/boba-tea")}>Boba Tea</h6>
                  <h6>
                    <a href="https://nitro-tubbees.s3.eu-west-2.amazonaws.com/TUBBEES+COFFEE+MENU+JULY+24.pdf?utm_source=WEBSITE&utm_campaign=Coffee_Menu">Coffee &amp; Drinks</a>
                  </h6>
                </div>
              </div>

              <h6>
                <a
                  onClick={() => (document.title = "Tubbees | Story")}
                  href="/#story"
                >
                  About
                </a>
              </h6>
              <h6>
                <a
                  onClick={() => (document.title = "Tubbees | Stores")}
                  href="/#stores"
                >
                  Our Stores
                </a>
              </h6>
            </div>
            <img
              src={logo}
              alt="Tubbees halloween logo"
              className="tubbeesLogo"
              onClick={() => {
                document.title = "Tubbees";
                window.location.href = "/";
              }}
              style={{ cursor: "pointer" }}
            />
            <div className="leftNav">
              {/* <div
                className="dropdown"
                style={{ marginTop: "-0.9%", marginLeft: "15%" }}
              >
                <h6>Collection</h6>
                <div className="dropdown-content">
                  <h6>
                    <a
                      href="https://pay.yoello.com/v/tubbeesibrox"
                      target="_blank"
                    >
                      Autoport
                    </a>
                  </h6>
                  <h6>
                    <a href="https://pay.yoello.com/v/rtstrath" target="_blank">
                      Strathclyde
                    </a>
                  </h6>
                </div>
              </div> */}
              <h6 style={{ marginLeft: "20%" }}>
                <a href="http://tubbees.co.uk/franchise">Franchise</a>
              </h6>
              <h6 style={{ marginLeft: "20%" }}>
                <a href="https://tubbees.co.uk/apply">Jobs</a>
              </h6>
              <h6 style={{ marginLeft: "20%" }}>
                <a href="https://shop.tubbees.co.uk" target="__blank">
                  Shop
                </a>
              </h6>
            </div>
            <img
              src={MenuIcon}
              sx={{ color: "#fff" }}
              className="menuIcon"
              onClick={() => handleMenu()}
              alt="menu icon"
            />
            {mobileMenu ? (
              <div className="mobileMenu">
                <p>
                  <a
                    onClick={() => {
                      document.title = "Tubbees | Slush";
                    }}
                    href="/#slush"
                  >
                    Slushees
                  </a>
                </p>
                <p>
                  <a
                    onClick={() => (document.title = "Tubbees | Story")}
                    href="/#story"
                  >
                    About
                  </a>
                </p>
                <p>
                  <a
                    onClick={() => (document.title = "Tubbees | Stores")}
                    href="/#stores"
                  >
                    Our Stores
                  </a>
                </p>
                <p>
                  <a href="#footer">Apply</a>
                </p>
                <p>
                  <a href="https://shop.tubbees.co.uk" target="__blank">
                    Shop
                  </a>
                </p>
                <p onClick={() => setShowDrop(!showDrop)}>Menu
                  {/* <a
                    onClick={() => (document.title = "Tubbees | Desserts")}
                    href="https://nitro-tubbees.s3.eu-west-2.amazonaws.com/TUBBEES+MENU+2024.pdf?utm_source=WEBSITE&utm_campaign=MENU"
                  >
                    Menu
                  </a> */}
                </p>
                {showDrop ?
                  <div style={{display:"block"}}>
                    <a style={{marginLeft:"20px",fontSize:"0.8rem"}} onClick={() => navigate("/boba-tea")}>Boba Tea</a> <hr/>
                    <a style={{marginLeft:"20px",fontSize:"0.8rem"}} href="https://nitro-tubbees.s3.eu-west-2.amazonaws.com/TUBBEES+COFFEE+MENU+JULY+24.pdf?utm_source=WEBSITE&utm_campaign=Coffee_Menu">Coffee</a><hr/>
                    <a style={{marginLeft:"20px",fontSize:"0.8rem"}} href="https://nitro-tubbees.s3.eu-west-2.amazonaws.com/TUBBEES+MENU+2024.pdf?utm_source=WEBSITE&utm_campaign=MENU">Desserts</a>
                  </div >
                  : null
                }
              </div>
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            <div className="leftNav">
              <h6>
                <a
                  onClick={() => {
                    document.title = "Tubbees | Slush";
                  }}
                  href="#slush"
                >
                  Slushees
                </a>
              </h6>
              <div
                className="dropdown"
              >
                <h6>
                  <a href="https://nitro-tubbees.s3.eu-west-2.amazonaws.com/TUBBEES+MENU+2024.pdf?utm_source=WEBSITE&utm_campaign=MENU">Menu</a>
                </h6>
                <div className="dropdown-content">
                  <h6 onClick={() => navigate("/boba-tea")}>Boba Tea</h6>
                  <h6>
                    <a href="https://nitro-tubbees.s3.eu-west-2.amazonaws.com/TUBBEES+COFFEE+MENU+JULY+24.pdf?utm_source=WEBSITE&utm_campaign=Coffee_Menu">Coffee &amp; Drinks</a>
                  </h6>
                </div>
              </div>

              <h6>
                <a
                  onClick={() => (document.title = "Tubbees | Story")}
                  href="#story"
                >
                  About
                </a>
              </h6>
              <h6>
                <a
                  onClick={() => (document.title = "Tubbees | Stores")}
                  href="#stores"
                >
                  Our Stores
                </a>
              </h6>
            </div>
            <img
              src={logo}
              alt="Tubbees halloween logo"
              className="tubbeesLogo"
              onClick={() => {
                document.title = "Tubbees";
                navigate("/");
              }}
              style={{ cursor: "pointer" }}
            />
            <div className="leftNav">
              {/* <div
                className="dropdown"
                style={{ marginTop: "-0.9%", marginLeft: "15%" }}
              >
                <h6>Collection</h6>
                <div className="dropdown-content">
                  <h6>
                    <a
                      href="https://pay.yoello.com/v/tubbeesibrox"
                      target="_blank"
                    >
                      Autoport
                    </a>
                  </h6>
                  <h6>
                    <a href="https://pay.yoello.com/v/rtstrath" target="_blank">
                      Strathclyde
                    </a>
                  </h6>
                </div>
              </div> */}
              <h6 style={{ marginLeft: "20%" }}>
                <a href="https://tubbees.co.uk/franchise">Franchise</a>
              </h6>
              <h6 style={{ marginLeft: "20%" }}>
                <a href="https://tubbees.co.uk/apply">Jobs</a>
              </h6>
              <h6 style={{ marginLeft: "20%" }}>
                <a href="https://shop.tubbees.co.uk" target="__blank">
                  Shop
                </a>
              </h6>
            </div>
            <img
              src={MenuIcon}
              sx={{ color: "#fff" }}
              className="menuIcon"
              onClick={() => handleMenu()}
              alt="menu icon"
            />
            {mobileMenu ? (
              <div className="mobileMenu">
                <p>
                  <a
                    onClick={() => {
                      document.title = "Tubbees | Slush";
                    }}
                    href="#slush"
                  >
                    Slushees
                  </a>
                </p>
                <p>
                  <a
                    onClick={() => (document.title = "Tubbees | Story")}
                    href="#story"
                  >
                    About
                  </a>
                </p>
                <p>
                  <a
                    onClick={() => (document.title = "Tubbees | Stores")}
                    href="#stores"
                  >
                    Our Stores
                  </a>
                </p>
                <p>
                  <a href="#footer">Jobs</a>
                </p>
                <p>
                  <a href="https://shop.tubbees.co.uk" target="__blank">
                    Shop
                  </a>
                </p>
                <p onClick={() => setShowDrop(!showDrop)}>Menu
                  {/* <a
                    onClick={() => (document.title = "Tubbees | Desserts")}
                    href="https://nitro-tubbees.s3.eu-west-2.amazonaws.com/TUBBEES+MENU+2024.pdf?utm_source=WEBSITE&utm_campaign=MENU"
                  >
                    Menu2
                  </a> */}
                </p>
                {showDrop ?
                  <div style={{display:"block"}}>
                    <a style={{marginLeft:"20px",fontSize:"0.8rem"}} onClick={() => navigate("/boba-tea")}>Boba Tea</a> <hr/>
                    <a style={{marginLeft:"20px",fontSize:"0.8rem"}} href="https://nitro-tubbees.s3.eu-west-2.amazonaws.com/TUBBEES+COFFEE+MENU+JULY+24.pdf?utm_source=WEBSITE&utm_campaign=Coffee_Menu">Coffee</a><hr/>
                    <a style={{marginLeft:"20px",fontSize:"0.8rem"}} href="https://nitro-tubbees.s3.eu-west-2.amazonaws.com/TUBBEES+MENU+2024.pdf?utm_source=WEBSITE&utm_campaign=MENU">Desserts</a>
                  </div >
                  : null
                }
              </div>
            ) : null}
          </>
        )}
      </div>
    </nav>
  );
}
