import Nav from "./Nav";
import Slush from "./Slush";
import "./styles/slushee.css";
import { useEffect, useState } from "react";
import axios from "axios";
import Flavour from "./Flavour";
import Footer from "./Footer";

export default function Slushee() {
    const [leftSide, setLeftSide] = useState([])
    const [rightSide, setRightSide] = useState([])
    const [slushColour, setSlushColour] = useState("#f2a2c4")
    const [search, setSearch] = useState(false)
    const [current, setCurrent] = useState('')
    document.title = "Tubbees | Slushees";

    useEffect(() => {
        axios.get("https://nitrous.rt-itservices.co.uk/tubbeesSite/slush").then((res) => {
            let middle = Math.floor(res.data.length / 2)
            setLeftSide(res.data.slice(0, middle))
            setRightSide(res.data.slice(middle, res.data.length))
        }).catch((err) => {
            console.log(err)
        })
    }, [])

    const colourC = (colour) => {
        setSlushColour(colour)
        var element = document.getElementById("slushyTop");
        element.classList.remove("slushy");
        void element.offsetWidth;
        element.classList.add("slushy");
        var element = document.getElementById("slushyOut");
        element.classList.remove("slushy_out");
        void element.offsetWidth;
        element.classList.add("slushy_out");
    }

    const closeWindow = (name) => {
        setCurrent(name)
    }

    const reset = () => {
        setSearch(false)
        // setSlushColour("#f2a2c4")
    }

    return (
        <>
            <Nav />
            <h1>Slushee Flavours</h1>
            <section className="SlushContainer">
                <div className="slushSide">
                    {leftSide.map(function (d, i) {
                        return (
                            <Flavour key={i} currentName={current} showMark={search} resetSlush={reset} windowClosed={closeWindow} changeColour={colourC} name={d.name} description={d.description} colour={d.color} carbonated={d.carbonated} sugarfree={d.sf} image={d.image} vegan={d.vegan} vegetarian={d.vegetarian} sites={d.sites} />
                        )
                    })}
                </div>
                <div className="slushSide">
                    <Slush colour={slushColour} />
                </div>
                <div className="slushSide">
                    {rightSide.map(function (d, i) {
                        return (
                            <Flavour key={i} currentName={current} showMark={search} resetSlush={reset} windowClosed={closeWindow} changeColour={colourC} name={d.name} description={d.description} colour={d.color} carbonated={d.carbonated} sugarfree={d.sf} image={d.image} vegan={d.vegan} vegetarian={d.vegetarian} sites={d.sites} />
                        )
                    })}
                </div>
            </section>
            <br/>
            <Footer/>
        </>
    );
}
