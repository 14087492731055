import "../styles/story.css";
import slush from '../../images/icon/slushCupWhite.webp'


export default function Twenty() {
  return (
    <div className="containerStory">
      <div className="storyHeading">
        <h2>2020</h2>
        <img src={slush} alt="slush cup"/>
        <img src={slush} alt="slush cup"/>
      </div>
      <p>Carbonated slushees hit our store... and we brought you the JUMBO cup to fill with your favourite flavours.<br/><span>We launched our app to bring you rewards.</span></p>
    </div>
  );
}
