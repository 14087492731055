import "../styles/story.css";
import slush from '../../images/icon/slushCupWhite.webp'


export default function Twentytwo() {
  return (
    <div className="containerStory">
      <div className="storyHeading">
        <h2>2022</h2>
        <img src={slush} alt="slush cup"/>
        <img src={slush} alt="slush cup"/>
      </div>
      <p>So far this year we have opened 2 new stores: Bearsden &amp; Sauchiehall Street.<br/><span>With the aim to open MANY more!</span></p>
      {/* <p>So far this year we have opened our Bearsden store.<br/><span>With the aim to open MANY more!</span></p> */}
    </div>
  );
}
