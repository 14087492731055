// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../ave_fedan/AveFedan_PERSONAL_USE.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "*{\n    box-sizing: border-box;\n    /* max-width: 1920px; */\n    scroll-behavior: smooth;\n    /* overflow-x:hidden; */\n}\n::-webkit-scrollbar{\n    width: 15px;\n    }\n    ::-webkit-scrollbar-track{\n    background: rgb(242, 162, 196,0.7);\n    border-radius: 20px;\n    /* Xmas */\n    /* background: #23314c;\n    border-radius: 20px; */\n    /* END OF XMAS */\n\n    }\n    ::-webkit-scrollbar-thumb{\n    /* background: #73C5C7;\n    border-radius: 20px; */\n\n    /* XMAS */\n    background: #69c7c5;\n    border-radius: 20px;\n    /* END OF XMAS */\n    }\n@font-face {\n    font-family: Menu;\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n", "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,uBAAuB;IACvB,uBAAuB;IACvB,uBAAuB;AAC3B;AACA;IACI,WAAW;IACX;IACA;IACA,kCAAkC;IAClC,mBAAmB;IACnB,SAAS;IACT;0BACsB;IACtB,gBAAgB;;IAEhB;IACA;IACA;0BACsB;;IAEtB,SAAS;IACT,mBAAmB;IACnB,mBAAmB;IACnB,gBAAgB;IAChB;AACJ;IACI,iBAAiB;IACjB,4CAAgD;AACpD","sourcesContent":["*{\n    box-sizing: border-box;\n    /* max-width: 1920px; */\n    scroll-behavior: smooth;\n    /* overflow-x:hidden; */\n}\n::-webkit-scrollbar{\n    width: 15px;\n    }\n    ::-webkit-scrollbar-track{\n    background: rgb(242, 162, 196,0.7);\n    border-radius: 20px;\n    /* Xmas */\n    /* background: #23314c;\n    border-radius: 20px; */\n    /* END OF XMAS */\n\n    }\n    ::-webkit-scrollbar-thumb{\n    /* background: #73C5C7;\n    border-radius: 20px; */\n\n    /* XMAS */\n    background: #69c7c5;\n    border-radius: 20px;\n    /* END OF XMAS */\n    }\n@font-face {\n    font-family: Menu;\n    src: url(../ave_fedan/AveFedan_PERSONAL_USE.ttf);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
