import Nav from "../Nav";
import Footer from "../Footer";
import "./boba.scss";
import { useEffect, useState } from "react";
import pina from "./images/pinaColada.webp";
import coffee from "./images/coffee_lover.webp";
import energy from "./images/electric_energy.webp";
import guava from "./images/guava.webp";
import lemon from "./images/lemon_lime.webp";
import lychee from "./images/lychee.webp";
import milk from "./images/milk_tea.webp";
import orange from "./images/orange.webp";
import passion from "./images/passion_fruit.webp";
import peach from "./images/peach.webp";
import strawberry from "./images/strawberry.webp";
import summer from "./images/summer_fruits.webp";

export default function Boba() {
  document.title = "Tubbees | Boba Tea";
  const colors = ["#f2a2c4", "#73c5c7", "#F1588B", "#f2a2c4", "#86f3f5"];
  const numBalls = 500;
  const menu = ["menu"];
  const balls = [];

  useEffect(() => {
    var a = "";
    for (let i = 0; i < menu.length; i++) {
      for (let z = 0; z < numBalls; z++) {
        let ball = document.createElement("div");
        ball.classList.add("ball");
        ball.style.background =
          colors[Math.floor(Math.random() * colors.length)];
        // if (smallScreen) {
        //   ball.style.left = `${Math.floor(Math.random() * 88)}vw`;
        // } else if (mediumScreen) {
        //   ball.style.left = `${Math.floor(Math.random() * 88)}vw`;
        // }
        ball.style.top = `${Math.floor(Math.random() * 100)}vh`;
        ball.style.transform = `scale(${Math.random()})`;
        ball.style.width = `${Math.random()}em`;
        ball.style.height = ball.style.width;
        ball.style.left = `${Math.floor(Math.random() * 80)}vw`;
        document.getElementById(menu[i]).appendChild(ball);
        balls.push(ball);
      }
    }

    // Keyframes
    balls.forEach((el, i, ra) => {
      let to = {
        x: Math.random() * (i % 2 === 0 ? -11 : 11),
        y: Math.random() * 12,
      };

      let anim = el.animate(
        [
          { transform: "translate(0, 0)" },
          { transform: `translate(${to.x}rem, ${to.y}rem)` },
        ],
        {
          duration: (Math.random() + 1) * 7000, // random duration
          direction: "alternate",
          fill: "both",
          iterations: Infinity,
          easing: "ease-in-out",
        }
      );
    });

    for (let balls = 1 ; balls < 17 ; balls ++){
      function randomDelay(){
        return Math.floor(Math.random() * (2500 -1000) + 1000)
      }
      let animate = document.getElementById("ball_" + balls)
      animate.style.animationDelay = randomDelay()+"ms"
      window.scrollY =  () => {
        scrollFall();
      };
      const scrollFall = event => {
        // console.log("scrolling")
        animate.classList.add('scroll-animation')
        setTimeout(() => {
          animate.classList.remove('scroll-animation', scrollFall)
        },2500)
    
      }
      window.addEventListener('scroll', scrollFall)
  
    }

  }, []);

  const scrollFall = () => {
    console.log("scroll");
    console.log(window.pageYOffset);
  };

  return (
    <section className="boba">
      <Nav />
      <h2>Boba tea menu</h2>
      <div className="menu">
        <div id="menu"></div>
        <div className="tea">
          <img src={milk} alt="pina colada boba tea" />
          <div className="row">
            <h6>Classic Milk Tea</h6>
            <p className="bobaText">
              With Black tea and a choice of <b>coffee</b> or <b>chocolate</b>{" "}
              balls.
            </p>
          </div>
        </div>
        <div className="tea">
          <img src={orange} alt="pina colada boba tea" />
          <div className="row">
            <h6>Mango Melody</h6>
            <p className="bobaText">Mango black tea with mango fruit balls.</p>
          </div>
        </div>
        <div className="tea">
          <img src={lychee} alt="pina colada boba tea" />
          <div className="row">
            <h6>Luscious Lychee</h6>
            <p className="bobaText">
              Lychee jasmine green tea with lychee fruit balls.
            </p>
          </div>
        </div>
        <div className="tea">
          <img src={passion} alt="pina colada boba tea" />
          <div className="row">
            <h6>Passion Fruit Martini</h6>
            <p className="bobaText">
              Passion fruit martini jasmine green tea with passion fruit, fruit
              balls.
            </p>
          </div>
        </div>
        <div className="tea">
          <img src={strawberry} alt="pina colada boba tea" />
          <div className="row">
            <h6>Strawberry daquiri</h6>
            <p className="bobaText">
              Strawberry Daquiri black tea with strawberry fruit balls.
            </p>
          </div>
        </div>
        <div className="tea">
          <img src={coffee} alt="pina colada boba tea" />
          <div className="row">
            <h6>Piña-colada</h6>
            <p className="bobaText">
              Piña-colada jasmine green tea with pineaple balls.
            </p>
          </div>
        </div>
        <div className="tea">
          <img src={coffee} alt="pina colada boba tea" />
          <div className="row">
            <h6>Coffee Lover</h6>
            <p className="bobaText">
              Iced coffee with <b>coffee</b> or <b>chocolate</b> balls.
            </p>
          </div>
        </div>
        <div className="tea">
          <img src={guava} alt="pina colada boba tea" />
          <div className="row">
            <h6>Guava &amp; Pomegranate</h6>
            <p className="bobaText">
              Guava jasmine green tea with pomegranate fruit balls.
            </p>
          </div>
        </div>
        <div className="tea">
          <img src={peach} alt="pina colada boba tea" />
          <div className="row">
            <h6>Peach &amp; apricot</h6>
            <p className="bobaText">
              Peach and apricot jasmine green tea with peach fruit balls.
            </p>
          </div>
        </div>
        <div className="tea">
          <img src={energy} alt="pina colada boba tea" />
          <div className="row">
            <h6>Electric Energy</h6>
            <p className="bobaText">
              Energy jasmine green tea with lemon fruit balls.
            </p>
          </div>
        </div>
        <div className="tea">
          <img src={lemon} alt="pina colada boba tea" />
          <div className="row">
            <h6>Lemon &amp; Lime</h6>
            <p className="bobaText">
              Lemon and Lime jasmine green tea with apple fruit balls.
            </p>
          </div>
        </div>
        <div className="tea">
          <img src={summer} alt="pina colada boba tea" />
          <div className="row">
            <h6>Summer Fruits</h6>
            <p className="bobaText">
              Mixed fruits jasmine green tea with blueberry fruit balls.
            </p>
          </div>
        </div>
      </div>
      <div className="balls" id="balls" onScroll={() => scrollFall}>
        <div className="ball" id="ball_1"></div>
        <div className="ball" id="ball_2"></div>
        <div className="ball" id="ball_3"></div>
        <div className="ball" id="ball_4"></div>
        <div className="ball" id="ball_5"></div>
        <div className="ball" id="ball_6"></div>
        <div className="ball" id="ball_7"></div>
        <div className="ball" id="ball_8"></div>
        <div className="ball" id="ball_9"></div>
        <div className="ball" id="ball_10"></div>
        <div className="ball" id="ball_11"></div>
        <div className="ball" id="ball_12"></div>
        <div className="ball" id="ball_13"></div>
        <div className="ball" id="ball_14"></div>
        <div className="ball" id="ball_15"></div>
        <div className="ball" id="ball_16"></div>
      </div>
      <Footer />
    </section>
  );
}
