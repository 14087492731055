import "./styles/desserts.css";
import pancakes from "../images/pancakes.webp";
import waffles from "../images/Waffle.webp";
import fudge from "../images/fudgePuppies.webp";
import crepes from "../images/crepes.webp";
import Slide from "react-reveal/Slide";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";


export default function Desserts() {
  const [test1, setTest1] = useState('')
  const navigate = useNavigate();
  const colors = ["#f2a2c4", "#73c5c7", "#F1588B", "#f2a2c4", "#86f3f5"];
  const numBalls = 200;
  const balls = [];
  var desserts5=["dessertItem1","dessertItem2","dessertItem3","dessertItem4"]
  const smallScreen = useMediaQuery({ query: "(max-width: 800px)" });
  const mediumScreen = useMediaQuery({ query: "(min-width: 801px)" });

  useEffect(() => {
    var a = "";
    for (let i = 0; i < desserts5.length; i++) {

      for (let z = 0; z < numBalls; z++) {
        let ball = document.createElement("div");
        ball.classList.add("ball");
        ball.style.background = colors[Math.floor(Math.random() * colors.length)];
        if (smallScreen) {
          ball.style.left = `${Math.floor(Math.random() * 88)}vw`;
        } else if (mediumScreen) {
          ball.style.left = `${Math.floor(Math.random() * 88)}vw`;
        }
        ball.style.top = `${Math.floor(Math.random() * 100)}vh`;
        ball.style.transform = `scale(${Math.random()})`;
        ball.style.width = `${Math.random()}em`;
        ball.style.height = ball.style.width;
        document.getElementById(desserts5[i]).appendChild(ball)
        balls.push(ball);
      }
    }

    // Keyframes
    balls.forEach((el, i, ra) => {
      let to = {
        x: Math.random() * (i % 2 === 0 ? -11 : 11),
        y: Math.random() * 12
      };

      let anim = el.animate(
        [
          { transform: "translate(0, 0)" },
          { transform: `translate(${to.x}rem, ${to.y}rem)` }
        ],
        {
          duration: (Math.random() + 1) * 7000, // random duration
          direction: "alternate",
          fill: "both",
          iterations: Infinity,
          easing: "ease-in-out"
        }
      );
    });
  }, [])

  return (
    <section id="desserts">
      <div className="item">
        <Slide left>
          <div id="dessertItem1"></div>
          <div className="img">
            <img src={pancakes} alt="pancake stack" style={{ zIndex: "100" }} />
            <svg viewBox="-20 15 200 200" style={{ zIndex: "-10" }}>
              <path
                fill="#f2a2c4"
                d="M34.5,-32.3C51,-18,75,-9,77.5,2.5C80,14,61.1,28.1,44.6,37.8C28.1,47.4,14,52.7,2.4,50.3C-9.3,47.9,-18.5,37.9,-24.2,28.2C-29.8,18.5,-31.9,9.3,-35.8,-3.9C-39.7,-17.1,-45.5,-34.2,-39.8,-48.5C-34.2,-62.9,-17.1,-74.6,-4.1,-70.6C9,-66.5,18,-46.7,34.5,-32.3Z"
                transform="translate(100 100)"
              />
            </svg>
          </div>
        </Slide>
        <div className="text">
          <h3 className="textPink">Yummy</h3>
          <h2>Pancake Stacks</h2>
          <p>
            Our delicious golden pancakes come topped full of surprises &amp;
            treats, as well as a generous topping of sauce of your choice
          </p>
          <button className="pink" >Menu Coming Soon</button>
        </div>
      </div>
      <div className="item">
        <div className="text">
          <h3 className="textGreen">Tasty</h3>
          <h2>Waffles</h2>
          <p>
            Our tasty waffles are full of incredible surprises &amp; treats,
            they also come with generous topping of sauce of your choice
          </p>
          <button className="green" >Menu Coming Soon</button>
        </div>
        <Slide right>
          <div id="dessertItem2"></div>
          <div className="imgRight">
            <img src={waffles} alt="waffle stack" />
            <svg viewBox="30 40 145 145">
              <path
                fill="#73c5c7"
                d="M46.9,-39.6C61.9,-32,75.8,-16,74.2,-1.6C72.6,12.7,55.4,25.5,40.4,39.2C25.5,53,12.7,67.8,-0.6,68.3C-13.9,68.9,-27.8,55.3,-38.2,41.5C-48.6,27.8,-55.5,13.9,-59.9,-4.4C-64.3,-22.6,-66.1,-45.3,-55.7,-52.9C-45.3,-60.5,-22.6,-53.1,-3.3,-49.8C16,-46.5,32,-47.2,46.9,-39.6Z"
                transform="translate(100 100)"
              />
            </svg>
          </div>
        </Slide>
      </div>
      <div className="item">
        <Slide left>
          <div id="dessertItem3"></div>
          <div className="img">
            <img src={fudge} alt="fudge puppies" />
            <svg viewBox="-80 15 260 260" className="first">
              <path
                fill="#f2a2c4"
                d="M39.4,-53.1C51.7,-45.3,62.6,-34.6,66,-22C69.4,-9.3,65.2,5.2,62.3,22.1C59.4,39,57.8,58.2,47.7,70.8C37.5,83.4,18.7,89.3,2.8,85.4C-13.1,81.5,-26.1,67.7,-35.3,54.8C-44.4,41.9,-49.6,29.9,-53.4,17.5C-57.2,5.2,-59.6,-7.5,-59.8,-23.4C-60.1,-39.3,-58.2,-58.4,-47.9,-66.9C-37.6,-75.3,-18.8,-73,-2.6,-69.5C13.6,-65.9,27.1,-61,39.4,-53.1Z"
                transform="translate(100 100)"
              />
            </svg>
          </div>
        </Slide>
        <div className="text">
          <h3 className="textPink">Delightful</h3>
          <h2>Fudge Puppies</h2>
          <p>
            Our delightful fudge puppies are prepared with the finest
            ingredients &amp; prepared in store while you wait, they are also
            topped with any sauce of your choice
          </p>
          <button className="pink" >Menu Coming Soon</button>
        </div>
      </div>
      <div className="item">
        <div className="text">
          <h3 className="textGreen">Scrumptious</h3>
          <h2>Crepes</h2>
          <p>
            Our mouth watering crepes come filled with incredible ingredients, topped with delicious sauces to
            create the perfect combination.
          </p>
          <button className="green">Menu Coming Soon</button>
        </div>
        <Slide right>
          <div id="dessertItem4"></div>
          <div className="imgRight">
            <img src={crepes} alt="crepes" />
            <svg viewBox="50 80 110 110">
              <path
                fill="#73c5c7"
                d="M49.8,-4.3C59.3,13.1,58.2,45.8,42.6,56.6C27.1,67.4,-2.8,56.2,-22.9,39.7C-43.1,23.2,-53.4,1.2,-47.9,-10.5C-42.4,-22.3,-21.2,-23.9,-0.5,-23.7C20.2,-23.5,40.3,-21.6,49.8,-4.3Z"
                transform="translate(100 100)"
              />
            </svg>
          </div>
        </Slide>
      </div>
    </section>
  );
}
