import Footer from "../Footer";
import Nav from "../Nav";
import SideMenu from "./SideMenu";
import pancakes from "../../images/icon/Pancake.svg";
import { useState } from "react";
import mermaid from "./hotdessert/pancakes/mermaid.webp";
import maple from "./hotdessert/pancakes/maple.webp";
import chocHeaven from "./hotdessert/pancakes/choc_heaven.webp";
import brownie from "./hotdessert/pancakes/brownie.webp";
import ambasador from "./hotdessert/pancakes/ambasador.webp";
import Cats from "./cats/Cats";

export default function HotDesserts() {
  document.title = "Tubbees | Menu - Hot Desserts";
  const [pancakeStack, setPancakeStack] = useState(true);
  const [crepes, setCrepes] = useState(false);
  const [waffles, setWaffles] = useState(false);
  const [fudgePuppies, setFudgePuppies] = useState(false);

  const desserts = [pancakeStack, crepes, waffles, fudgePuppies];

  function handleDesserts(dessert) {
    if (dessert === "pancake"){
      setCrepes(false)
      setWaffles (false)
      setFudgePuppies(false)
      setPancakeStack(true)
    } else if (dessert === "crepes") {
      setWaffles (false)
      setFudgePuppies(false)
      setPancakeStack(false)
      setCrepes(true)
    } else if (dessert === "waffles") {
      setFudgePuppies(false)
      setPancakeStack(false)
      setCrepes(false)
      setWaffles (true)
    } else if (dessert === "fudge"){
      setPancakeStack(false)
      setCrepes(false)
      setWaffles (false)
      setFudgePuppies(true)
    }
  }

  return (
    <main>
      <Nav />
      <section className="menu">
        <aside>
          <SideMenu />
        </aside>
        <div className="menuContent">
          <h2>Our hot desserts</h2>
          <div className="row">
            <div className="item" onClick={() => handleDesserts("pancake")}>
              <img src={pancakes} alt="Pancake Stacks" />
              <h4>Pancake Stacks</h4>
            </div>
            <div className="item" onClick={() => handleDesserts("crepes")}>
              <img src={pancakes} alt="Pancake Stacks" />
              <h4>Crepes</h4>
            </div>
            <div className="item" onClick={() => handleDesserts("waffles")}>
              <img src={pancakes} alt="Pancake Stacks" />
              <h4>Waffles</h4>
            </div>
            <div className="item" onClick={() => handleDesserts("fudge")}>
              <img src={pancakes} alt="Pancake Stacks" />
              <h4>Fudge Puppies</h4>
            </div>
          </div>
          {pancakeStack ? <Cats category={"hot"} child={"pancakes"} /> : <></>}
          {crepes ? <Cats category={"hot"} child={"crepes"} /> : <></>}
          {waffles ? <Cats category={"hot"} child={"waffles"} /> : <></>}
          {fudgePuppies ? <Cats category={"hot"} child={"fudge"} /> : <></>}
          <Cats category={"hot"} child={"pancakes"} />
        </div>
      </section>
      <Footer />
    </main>
  );
}
