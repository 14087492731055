import React from "react";
import "./styles/slushFlavours.css";
import axios from "axios";
import { useEffect, useState } from "react";
import Flavour from "./slush/Flavour";
import { useMediaQuery } from "react-responsive";
import CountUp from "react-countup";
import AddIcon from '@mui/icons-material/Add';

export default function SlushFlavours() {
  const [flavours, setFlavours] = useState([]);
  const [all, setAll] = useState([]);
  const [plus,setPlus]=useState(false)
  //   const [numberFlavour, setNumberFlavour] = useState(0);
  const smallScreen = useMediaQuery({ query: "(max-width: 800px)" });
  const mediumScreen = useMediaQuery({ query: "(min-width: 801px)" });

  useEffect(() => {
    async function getFlavours() {
      await axios
        .get("https://nitrous.rt-itservices.co.uk/tubbeesSite/slush")
        .then((res) => {
          setAll(res.data)
          let shuffle = res.data.sort(() => Math.random() - 0.5)
          var data = [];
          for (let i = 0; i < 20; i++) {
            var find = data.find((d) => d.name === shuffle[i].name);
            if (!find) {
              data.push(shuffle[i])
            }
          }
          if (smallScreen) {
            setFlavours(data.slice(0, 9));
          } else if (mediumScreen) {
            setFlavours(data.slice(0, 15));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    getFlavours();
    setInterval(async () => {
      getFlavours();
    }, 5000);
  }, []);

  return (
    <section id="slush">
      <CountUp start={0} end={90} delay={0} duration={4} onEnd={() => setPlus(true)}>
        {({ countUpRef }) => (
          <div>
            <h1 className="slushHeader">
            {/* <AddIcon className="addIcon" sx={{color:"#fff",fontSize:50}} stroke={"#000"} strokeWidth={0.8}/> */}
              <span ref={countUpRef} />{plus?<> PLUS </>:<></>}FLAVOURS
            </h1>
          </div>
        )}

      </CountUp>

      <div className="grid">
        {flavours ? (
          flavours.map(function (d, i) {
            return (
              <Flavour
                key={i}
                name={d.name}
                color={d.color}
                description={d.description}
              />
            );
          })
        ) : (
          <></>
        )}
      </div>
    </section>
  );
}
