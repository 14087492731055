import "./styles/franchise.scss";
import Nav from "./Nav";
import Footer from "./Footer";
import { useState } from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import slush from "../images/icon/slushCupBlack.webp";
import axios from "axios";

export default function Franchise() {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [warning, setWarning] = useState(false);
  const [submitBtn, setSubmitBtn] = useState(true);
  const [loader, setLoader] = useState(false);
  const [sent, setSent] = useState(false);

  document.title = "Tubbees | Franchise";

  async function handleFranchise() {
    var data = {
      name: name,
      surname: surname,
      phone: phone,
      city: city,
      message: message,
      email: email,
    };
    if (
      name === "" ||
      surname === "" ||
      phone === "" ||
      city === "" ||
      message === "" ||
      email === ""
    ) {
      setTimeout(() => {
        setWarning(true);
      }, 2500);
    } else {
      setSubmitBtn(false)
      setLoader(true)
      await axios.post("https://nitrous.rt-itservices.co.uk/tubbeesSite/franchise", data)
        .then((res) => {
          setLoader(false)
          setSent(true)
          window.location.reload()
        }).catch((err) => {
          console.log(err)
        })
      
    };
  }

  return (
    <section className="franchise">
      <Nav />
      <h2>Apply for a Franchisee pack</h2>
      {warning ? (
        <Alert severity="warning" sx={{ width: "80%", margin: "auto" }}>
          <AlertTitle>Warning</AlertTitle>
          All fields must be filled
        </Alert>
      ) : (
        <></>
      )}
      <div className="form">
        <label>Name:</label>
        <input type="text" onChange={(e) => setName(e.target.value)} />
        <label>Surname:</label>
        <input type="text" onChange={(e) => setSurname(e.target.value)} />
        <label>Contact Number:</label>
        <input type="text" onChange={(e) => setPhone(e.target.value)} />
        <label>Email</label>
        <input type="text" onChange={(e) => setEmail(e.target.value)} />
        <label>Which city do you want to open a franchise?</label>
        <input type="text" onChange={(e) => setCity(e.target.value)} />
        <label>More information</label>
        <textarea
          cols="40"
          rows="5"
          onChange={(e) => setMessage(e.target.value)}
        />
        {submitBtn ? (
          <button onClick={() => handleFranchise()}>Submit</button>
        ) : (
          <></>
        )}
        {loader ? <img src={slush} alt="slush loader" /> : <></>}
        {sent ? (
          <svg
            class="checkmark"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
          >
            <circle
              class="checkmark__circle"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            />
            <path
              class="checkmark__check"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
            />
          </svg>
        ) : (
          <></>
        )}
      </div>
      <Footer />
    </section>
  );
}
