import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Menu from './components/Menu';
import Home from './components/Home';
import BookTable from './components/BookTable';
import Slushee from './components/Slushee';
import Application from './components/Application';
import NotFound from './components/NotFound';
import Franchise from './components/Franchise';
import Boba from './components/bobatea/Boba';
import NewMenu from './components/menu/Menu';
import HotDesserts from './components/menu/HotDesserts';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          {/* <Route path="/menu" element={<Menu />} /> */}
          <Route path="/menu" element={<NewMenu />} />
          <Route path="/menu/hot-desserts" element={<HotDesserts />}/>
          <Route path="/booktable" element={<BookTable />} />
          <Route path="/slushees" element={<Slushee />} />
          <Route path="/apply" element={<Application />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/franchise" element={<Franchise />}/>
          <Route path="/boba-tea" element={<Boba />}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
