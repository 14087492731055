import "./menu.scss";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import cone from "../../images/icon/Cone.svg";

export default function SideMenu(props) {
  const navigate = useNavigate();
  // const [dessert, setDessert] = useState("")

  // useEffect(() => {
  //   // setDessert (props.dessert)
  //   let dessert = document.getElementById(props.dessert);
  //   dessert.style.cssText = "underline: 2px solid #f2a2c4; border-bottom: 2px solid #f2a2c4; transform: scale(1.2);";
  // });

  return (
    <main>
      <section className="sideMenu">
        <h6 onClick={() => navigate("/menu/hot-desserts")}>
          <img src={cone} alt="Sundae Icon" />
          <span id="hot">hot desserts</span>
        </h6>
        <h6>
          <span className="cookie">cookie dough</span>
          <img src={cone} alt="Sundae Icon" />
        </h6>
        <h6>
          <img src={cone} alt="Sundae Icon" />
          <span className="freak">freakshakes</span>
        </h6>
        <h6>
          <span className="sundae">sundaes </span>
          <img src={cone} alt="Sundae Icon" />
        </h6>
        <h6>
          <img src={cone} alt="Sundae Icon" />
          <span className="churro">churros</span>
        </h6>
        <h6>
          <span className="milk">milkshakes</span>
          <img src={cone} alt="Sundae Icon" />
        </h6>
        <h6>
          <img src={cone} alt="Sundae Icon" />
          <span className="boba">boba tea</span>
        </h6>
      </section>
    </main>
  );
}
