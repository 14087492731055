import "./styles/notfound.css";
import logo from '../images/Round-logo.webp';

export default function NotFound() {
    return (
        <section className="pageNot">
            <img src={logo} alt="logo" width="10%" className="logoTop" />
            <h2>Oh no! Page not found!</h2>
            <div className="notfound">
                <div className="insideNotFound"></div>
            </div>
            <p>Try going to <a href="https://tubbees.co.uk">Tubbees Home Page</a></p>
        </section>
    );
}