import "./scssStyles/menu.scss";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Category from "./categories/Category";
import React from "react";
import { createTheme, useTheme, ThemeProvider } from '@mui/material/styles';
import Nav from "./Nav";
import Footer from './Footer'
import Nitrolab from "./categories/NitroLab";
import ComingSoon from "./categories/ComingSoon";


function TabPanel(props) {
  document.title = "Tubbees | Menu";

  const { children, value, index, ...other } = props;


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function Menu() {
  const [value, setValue] = React.useState(0);
  document.title="Tubbees | Menu"

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const theme = createTheme({
    palette: {
      primary: { main: '#f2a2c4' },
      secondary: { main: '#73c5c7' },
    }
  })




  return (
    <>
      <Nav />
      <ThemeProvider theme={theme}>
        <Box sx={{ flexGrow: 1, bgcolor: "Background.paper", marginTop: "11%", width: "100%" }}>
          {/* <Box sx={{ borderBottom: 2, borderColor: "divider" }}> */}
          <Tabs
            orientation="horizontal"
            value={value}
            onChange={handleChange}
            aria-label="Tubbees Menu"
            variant="scrollable"
            centered
            // scrollButtons
            allowScrollButtonsMobile
            indicatorColor="primary"
            sx={{ borderRight: 0, borderColor: "primary" }} style={{width:"58%",margin:"0 auto"}}
          >
            <Tab label='Crepes, Pancake Stacks, Fudge Puppes, Waffles' {...a11yProps(0)} />
            <Tab label="Cookie Dough" {...a11yProps(1)} />
            <Tab label="Sundaes" {...a11yProps(2)} />
            <Tab label="Freakshakes" {...a11yProps(3)} />
            <Tab label="Churros" {...a11yProps(4)} />
            <Tab label="Smoothies" {...a11yProps(5)} />
            <Tab label="Milkshakes" {...a11yProps(6)} />
            {/* <Tab label="Drinks" {...a11yProps(7)} /> */}
            {/* <Tab label="Nitro Lab" {...a11yProps(8)} /> */}
          </Tabs>
          <TabPanel value={value} index={0}>
            <Category category={"hot"} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Category category={"cookie"} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Category category={"sundaes"} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Category category={"freakshake"} />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <Category category={"churros"} />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <Category category={"smoothies"} />
          </TabPanel>
          <TabPanel value={value} index={6}>
            <Category category={"milkshakes"} />
          </TabPanel>
          {/* <TabPanel value={value} index={7}>
            <Category category={"drinks"} />
          </TabPanel> */}
          {/* <TabPanel value={value} index={8}>
          <Nitrolab />
        </TabPanel> */}
        </Box>
      </ThemeProvider>
      <br/>
      <Footer/>
    </>
  );
}
