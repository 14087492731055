import "./styles/footer.css";
import logo from "../images/Round-logo.webp";
import facebook from "../images/sm/facebook.webp";
import insta from "../images/sm/instagram.webp";
import tiktok from "../images/sm/tiktok.webp";
import youtube from "../images/sm/youtube.webp";
import twitter from "../images/sm/TWITTER_TRANSPARENT.png";
import tuckshop from '../images/tuckshop_logo.webp'
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";

export default function Footer() {
  const navigate = useNavigate();
  const today = new Date();

  const [email, setEmail] = useState("");

  const Join = async (e) => {
    var data = { email: email };
    if (email.length === 0) {
      alert("Please insert a valid email address");
    } else {
      await axios
        .post("https://nitrous.rt-itservices.co.uk/tubbeesSite/mailchimp", data)
        .then((res) => {
          alert("Thank you for joining our mailing list");
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <footer id="footer">
      <div className="footerContainer">
        <div className="column">
          <img src={logo} alt="logo" className="logo" />
        </div>
        <div className="column">
          <h4>Join Us</h4>
          <p onClick={() => navigate("/apply")} style={{ cursor: "pointer" }}>
            Apply to join the team
          </p>
          <p onClick={() => navigate("/franchise")} style={{ cursor: "pointer", marginTop: "0" }}>
            Apply for franchise
          </p>
          {/* <input
            type="email"
            placeholder="Get our updates"
            onChange={(e) => setEmail(e.target.value)}
          />
          <button onClick={() => Join()}>Join us</button> */}
        </div>
        <div className="column" style={{ marginTop: "-1%" }}>
          <h4>What's New?</h4>
          <p>Follow us for all our latest news.</p>
          <div className="smIcons">
            <a
              href="https://www.facebook.com/tubbees.desserts/"
              target="_blank"
            >
              <img src={facebook} alt="Facebook" />
            </a>
            <a
              href="https://www.instagram.com/tubbees.desserts/"
              target="_blank"
            >
              <img src={insta} alt="Instagram" />
            </a>
            <a href="https://www.tiktok.com/@tubbees.desserts/" target="_blank">
              <img src={tiktok} alt="Tik Tok" />
            </a>
            <a href="https://twitter.com/tubbeesdesserts" target="_blank">
              <img src={twitter} style={{width: "40%"}} alt="X" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCxQ_Bi2J2P2OZWEYTBQhXtg"
              target="_blank"
            >
              <img src={youtube} alt="Youtube" />
            </a>
          </div>
        </div>
        <div className="column tuckshop">
          <a href="https://shop.tubbees.co.uk" target="_blank" style={{cursor: "pointer"}}>
            {/* <p>Visit Tubbees Tuck Shop</p> */}
            <img src={tuckshop} alt="tubbees shop logo"/>
          </a>
        </div>
      </div>
      <div className="copy">
        <span>&copy; {today.getFullYear()} Tubbees</span>
        <p>
          Website Designed &amp; Developed by
          <a href="https://nitrosolutions.co.uk/" style={{ color: "#2c53a0" }}>
            Nitro Solutions
          </a>
        </p>
      </div>
    </footer>
  );
}
