import "../styles/slushFlavours.css";
import { useState } from "react";

export default function Flavour(props) {
  const [description, setDescription] = useState(false);

  return (
    <div
      className="slushProduct"
      style={{ backgroundColor: props.color }}
      // onMouseOver={() => setDescription(true)}
      // onMouseLeave={() => setDescription(false)}
    >
      <h6>{props.name}</h6>
      {/* <div className="popUp">
        {description ? (
          <>
            <div
              className="image"
              style={{ backgroundColor: props.color }}
            ></div>
            <div className="description">
              <h3>{props.name}</h3>
              <p>{props.description}</p>
            </div>
          </>
        ) : (
          <></>
        )}
      </div> */}
    </div>
  );
}
