import HeaderVideo from "../images/promotion.mp4";
import Nav from "./Nav";
import "./styles/Home.css";
import Welcome from "./Welcome";
import SlushFlavours from "./SlushFlavours";
import Slider from "./Slider";
import Wave from "./Wave";
import IconBar from "./IconBar";
import Desserts from "./Desserts";
import Stores from "./Stores";
import MenuBar from "./MenuBar";
import Footer from "./Footer";
import App from "./App";
import OurStory from "./OurStory";
// import Xmas from './xmas/Xmas';


export default function Home() {

  return (
    <section className="Home">
      <Nav />
      <div className="welcome-div">
        <Welcome />
        <div className="topVideo">
          <video autoPlay muted loop width="100%" height="100%" style={{cursor:"pointer"}}>
            <source src={HeaderVideo} type="video/mp4" style={{aspectRatio: "16/9"}}/>
          </video>
        </div>
        {/* <Xmas /> */}
      </div>
      <SlushFlavours />
      <Wave />
      <App />
      <IconBar />
      <Desserts />
      <MenuBar />
      <Slider />
      <OurStory />
      <Stores />
      <Footer />
    </section>
  );
}
