import "../styles/story.css";
import slush from '../../images/icon/slushCupWhite.webp'


export default function Nineteen() {
  return (
    <div className="containerStory">
      <div className="storyHeading">
        <h2>2019</h2>
        <img src={slush} alt="slush cup"/>
        <img src={slush} alt="slush cup"/>
      </div>
      <p>This was a big year! We DOUBLED our slushee flavours and opened our Dessert Bar.<br/><span>The FIRST to have Nitro Ice Cream.</span></p>
    </div>
  );
}
