import Logo from "../images/tubbees_slush_logo.webp";
import "./scssStyles/Slush.scss";

export default function Slush(props) {

  const pourSlush=()=>{
    var element = document.getElementById("slushyTop");
        element.classList.remove("slushy");
        void element.offsetWidth;
        element.classList.add("slushy");
        var element = document.getElementById("slushyOut");
        element.classList.remove("slushy_out");
        void element.offsetWidth;
        element.classList.add("slushy_out");
  }

  return (
    <div className="container">
      <div className="Uppertop" style={{backgroundColor:`${props.colour}`}}>
        <div className="top-overlay"></div>
        <div className="logo-bg"></div>
        <img className="Tubbees" src={Logo} />
      </div>

      <div className="middle">
        <div className="middle_square"></div>
        <div className="middle_circle" style={{background:`${props.colour}`,backgroundColor:`${props.colour}`}}>
          <div className="mixer"></div>

          <div className="handle">
            <div className="handle-base"></div>
            <div className="handle-pull" onClick={()=>{pourSlush()}}></div>
            <div className="handle-circle"></div>
            <div className="release"></div>
          </div>

          <div className="middle_circle_overlay" style={{backgroundColor:`${props.colour}`, opacity:"0.8"}}>
            <div class="bubble x1"></div>
            <div class="bubble x2"></div>
            <div class="bubble x3"></div>
            <div class="bubble x4"></div>
            <div class="bubble x5"></div>
            <div class="bubble x6"></div>
            <div class="bubble x7"></div>
            <div class="bubble x8"></div>

          </div>
        </div>

        <div className="bolt bolt1"></div>
        <div className="bolt bolt2"></div>
        <div className="bolt bolt3"></div>
        <div className="bolt bolt4"></div>
      </div>

      <div className="bottom">
        <div className="bottom_left"></div>
        <div className="bottom_right"></div>
        <div className="bottom_bottom"></div>
      </div>

      <div className="cup">
        <div id="slushyTop" className="slushy" style={{borderTopColor:`${props.colour}`}}></div>
      </div>
      <div className="cup-overlay"></div>

      <div id="slushyOut" className="slushy_out" style={{background: `linear-gradient(90deg, #DDE7E7 0%, ${props.colour} 0%, ${props.colour} 32%, ${props.colour} 51%, ${props.colour} 100%)`}}></div>
    </div>
  );
}
