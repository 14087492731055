import "./styles/Header.css";
import slush from "../images/icon/slushCupWhite.webp";
import Carousel2 from "./Carousel2";



export default function Wave() {
  return (
    <div className="wave" >
      <div className="inner-header flex">
        <div className="block" >
          <div className="Row" >
            <div className="dot"></div>
            <img src={slush} alt="slush cup" className="slushCup" />
            <div className="dot"></div>
            <img src={slush} alt="slush cup" className="slushCup2" />
            <div className="dot"></div>
            <img src={slush} alt="slush cup" className="slushCup1" />
            <div className="dot"></div>
          </div>
          <Carousel2 />
          <div className="Row">
            <img src={slush} alt="slush cup" className="slushCup" />
            <div className="dot"></div>
            <img src={slush} alt="slush cup" className="slushCup2" />
            <div className="dot"></div>
            <img src={slush} alt="slush cup" className="slushCup1" />
            <div className="dot"></div>
            <img src={slush} alt="slush cup" className="slushCup2" />
            <div className="dot"></div>
          </div>
        </div>
      </div>

      <div>
        <svg
          className="wavesTop"
          // width="100%"
          // height="200px"
          fill="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#f2a2c4" />
            <stop offset="50%" stopColor="#f2a2c4" />
            <stop offset="100%" stopColor="#f2a2c4" />
          </linearGradient>
          <path
            fill="url(#grad1)"
            d="
          M0 67
          C 273,183
            822,-40
            1920.00,106 
          
          V 359 
          H 0 
          V 67
          Z"
          >
            <animate
              repeatCount="indefinite"
              fill="url(#grad1)"
              attributeName="d"
              dur="15s"
              attributeType="XML"
              values="
            M0 77 
            C 473,283
              822,-40
              3820,116 
            
            V 359 
            H 0 
            V 67 
            Z; 

            M0 77 
            C 473,-40
              1222,283
              3820,136 
            
            V 359 
            H 0 
            V 67 
            Z; 

            M0 77 
            C 973,260
              1722,-53
              3820,120 
            
            V 359 
            H 0 
            V 67 
            Z; 

            M0 77 
            C 473,283
              822,-40
              3820,116 
            
            V 359 
            H 0 
            V 67 
            Z
            "
            ></animate>
          </path>
        </svg>

        <svg
          className="wavesBottom"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="24 24 150 28"
          preserveAspectRatio="none"
          shapeRendering="auto"
        >
          <defs>
            <path
              id="gentle-wave"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            />
          </defs>
          <g className="parallax">
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="0"
              fill="rgba(255,255,255,0.7"
            />
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="3"
              fill="rgba(255,255,255,0.5)"
            />
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="5"
              fill="rgba(255,255,255,0.3)"
            />
            <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
          </g>
        </svg>
      </div>
    </div>
  );
}
