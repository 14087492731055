import "../components/styles/app.css";
import app from '../images/phone.webp';
import apple from '../images/appStore.webp';
import google from '../images/googlePlay.webp'

export default function App() {
  return (
    <section className="app">
      <h2>Download Our App</h2>
      <div className="sides">
        <div className="side">
          <h4>Collect Points</h4>
          <h3>And Earn</h3>
          <h4 id="free">Free Slushees</h4>
          <div className="store">
              <a className="apple" href="https://apps.apple.com/gb/app/tubbees/id1501717401" target="_blank"><p></p></a>
              <a className="google" href="https://play.google.com/store/apps/details?id=com.tubbees.loyalty&hl=en_GB&gl=US" target="_blank"><p></p></a>
          </div>
        </div>
        <div className="side">
            <img src={app} alt="mobile phone"/> 
        </div>
      </div>
    </section>
  );
}
