import React, { useEffect } from "react";
import "../styles/story.css";
import "../scssStyles/glitch.scss";
import GlitchedWriter, {
  wait,
} from "https://cdn.skypack.dev/glitched-writer@2.0.29";
import slush from "../../images/icon/slushCupWhite.webp";

export default function Future(props) {
  useEffect(() => {
    if (props.displayText) {
      const Writer = new GlitchedWriter("#glitch_this", { letterize: true });

      (async () => {
        await wait(500);
        await Writer.write("This is only the beginning.");

        await wait(1100);
        await Writer.write("We are planning to take over the UK");

        await wait(1500);
        await Writer.write("The sky is the limit");
      })();
    }
  }, [props.displayText]);

  return (
    <div className="containerFut">
      <div className="storyHeading" >
        <h2>Future</h2>
        <img src={slush} alt="slush cup" />
        <img src={slush} alt="slush cup" />
      </div>

      <p>
        And much more to come...
        <br />
        <span id="glitch_this">Starting with Scotland.</span>
      </p>
    </div>
  );
}
