// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../Font/Bobbleboddy.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n    font-family: tubbees;\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    font-display: swap;\n}\n\n.booktable {\n    display: flex;\n    flex-direction: column;\n}\n\nh2{\n    text-align: center;\n    font-family: tubbees;\n    text-transform: uppercase;\n    font-size: 2rem;\n    color: #fff;\n    -webkit-text-stroke: 2px #000;\n}\n.bookInfo{\n    width: 90%;\n    margin: auto;\n    display: flex;\n    flex-direction: column;\n}\n\n.bookInfo p{\n    margin-bottom: 2%;\n    font-size: 1.2rem;\n}\niframe{\n    margin-top: 3%;\n}", "",{"version":3,"sources":["webpack://./src/components/styles/booktable.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,4CAAuC;IACvC,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,oBAAoB;IACpB,yBAAyB;IACzB,eAAe;IACf,WAAW;IACX,6BAA6B;AACjC;AACA;IACI,UAAU;IACV,YAAY;IACZ,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;AACrB;AACA;IACI,cAAc;AAClB","sourcesContent":["@font-face {\n    font-family: tubbees;\n    src: url(../../../Font/Bobbleboddy.ttf);\n    font-display: swap;\n}\n\n.booktable {\n    display: flex;\n    flex-direction: column;\n}\n\nh2{\n    text-align: center;\n    font-family: tubbees;\n    text-transform: uppercase;\n    font-size: 2rem;\n    color: #fff;\n    -webkit-text-stroke: 2px #000;\n}\n.bookInfo{\n    width: 90%;\n    margin: auto;\n    display: flex;\n    flex-direction: column;\n}\n\n.bookInfo p{\n    margin-bottom: 2%;\n    font-size: 1.2rem;\n}\niframe{\n    margin-top: 3%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
