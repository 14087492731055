import React,{useState} from 'react'
import './styles/story.css'
import Carousel from './StoryCarousel2'
import Eighteen from './story/2018';
import Nineteen from './story/2019';
import Twenty from './story/2020';
import Twentyone from './story/2121';
import Twentytwo from './story/2022';
import Future from './story/Future';

export default function OurStory() {

  const [showFuture,setShowFuture]=useState(false)

  const slideCheck=(val)=>{
    if(val===3){
      setShowFuture(!showFuture)
    }
  }

  return (
    <div id="story">
      <section>
        <h1>Our Story</h1>
        <svg
          className="storyWavesTop"
          width="100%"
          height="200px"
          fill="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#f2a2c4" />
            <stop offset="50%" stopColor="#f2a2c4" />
            <stop offset="100%" stopColor="#f2a2c4" />
          </linearGradient>
          <path
            fill="url(#grad1)"
            d="
          M0 67
          C 273,183
            822,-40
            3820.00,106 
          
          V 359 
          H 0 
          V 67
          Z"
          >
            <animate
              repeatCount="indefinite"
              fill="url(#grad1)"
              attributeName="d"
              dur="15s"
              attributeType="XML"
              values="
            M0 77 
            C 473,283
              822,-40
              3820,116 
            
            V 359 
            H 0 
            V 67 
            Z; 

            M0 77 
            C 473,-40
              1222,283
              3820,136 
            
            V 359 
            H 0 
            V 67 
            Z; 

            M0 77 
            C 973,260
              1722,-53
              3820,120 
            
            V 359 
            H 0 
            V 67 
            Z; 

            M0 77 
            C 473,283
              822,-40
              3820,116 
            
            V 359 
            H 0 
            V 67 
            Z
            "
            ></animate>
          </path>
        </svg>

          <Carousel show={2} checkSlide={slideCheck}>
                <div>
                    <div style={{ padding: 8, marginTop: "5%" }}>
                        <Eighteen />
                    </div>
                </div>
                <div>
                    <div style={{ padding: 8, marginTop: "5%"}}>
                        <Nineteen />
                    </div>
                </div>
                <div>
                    <div style={{ padding: 8, marginTop: "5%" }}>
                        <Twenty />
                    </div>
                </div>
                <div>
                    <div style={{ padding: 8, marginTop: "5%" }}>
                        <Twentyone />
                    </div>
                </div>
                <div>
                    <div style={{ padding: 8, marginTop: "5%" }}>
                        <Twentytwo />
                    </div>
                </div>
                <div>
                    <div style={{ padding: 8, marginTop: "10%" }}>
                    <Future displayText={showFuture}/>
                    </div>
                </div>

            </Carousel>

          <svg
          className="storyWavesBottom"
          width="100%"
          height="200px"
          fill="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#f2a2c4" />
            <stop offset="50%" stopColor="#f2a2c4" />
            <stop offset="100%" stopColor="#f2a2c4" />
          </linearGradient>
          <path
            fill="url(#grad1)"
            d="
          M0 67
          C 273,183
            822,-40
            3820.00,106 
          
          V 359 
          H 0 
          V 67
          Z"
          >
            <animate
              repeatCount="indefinite"
              fill="url(#grad1)"
              attributeName="d"
              dur="15s"
              attributeType="XML"
              values="
            M0 77 
            C 473,283
              822,-40
              3820,116 
            
            V 359 
            H 0 
            V 67 
            Z; 

            M0 77 
            C 473,-40
              1222,283
              3820,136 
            
            V 359 
            H 0 
            V 67 
            Z; 

            M0 77 
            C 973,260
              1722,-53
              3820,120 
            
            V 359 
            H 0 
            V 67 
            Z; 

            M0 77 
            C 473,283
              822,-40
              3820,116 
            
            V 359 
            H 0 
            V 67 
            Z
            "
            ></animate>
          </path>
        </svg>

      </section>
    </div>
  )
}
