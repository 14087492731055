import { useState } from "react";
import { MilkIcon, EggIcon, GlutenIcon, NutsIcon } from "react-allergens";
import FlipCameraAndroidIcon from "@mui/icons-material/FlipCameraAndroid";



export default function IndvCat(props) {
  const [cardFlip, setCardFlip] = useState("");


  function flip() {
    if (cardFlip === "") {
      setCardFlip("flip");
    } else {
      setCardFlip("");
    }
  }

  return (
    <div className={`dessert ${cardFlip}`} onClick={() => flip()}>
      <div
        className="face front glowing"
        style={{ backgroundImage: `url(${props.img})` }}
      >
        <h3>{props.name}</h3>
        <span id="blink">
          <FlipCameraAndroidIcon
            sx={{ color: "fff" }}
            fontSize="large"
            id="test"
          />
        </span>
      </div>
      <div className="face back">
        <h6>{props.name}</h6>
        <h5>What's on me?</h5>
        <div>
          {props.description.map(function (dd, i) {
            return <li key={i}>{dd}</li>;
          })}
        </div>
        <div className="bott">
          {/* <div className="cals">
            <p>kcal</p>
            <h4>{props.calories}</h4>
            <div className="perct">
              <h4>{props.percentage}</h4>
            </div>
          </div> */}
          <div className="aller">
            <h4>Allergens</h4>
            <div className="perct">
              {props.allergens.includes("milk") ? (
                <h4 title="Milk">
                  <MilkIcon
                    width={30}
                    height={30}
                    wrapperStyle={{ alignSelf: "center", margin: "-50% 0" }}
                    outerColor={"#f2a2c4"}
                  />
                </h4>
              ) : (
                <>
                  <br />
                </>
              )}
              {props.allergens.includes("egg") ? (
                <h4 title="Egg">
                  <EggIcon
                    width={30}
                    height={30}
                    wrapperStyle={{ alignSelf: "center", margin: "-50% 0" }}
                    outerColor={"#f2a2c4"}
                  />
                </h4>
              ) : (
                <>
                  <br />
                </>
              )}
              {props.allergens.includes("wheat") ? (
                <h4 title="Wheat">
                  <GlutenIcon
                    width={30}
                    height={30}
                    wrapperStyle={{ alignSelf: "center", margin: "-50% 0" }}
                    outerColor={"#f2a2c4"}
                  />
                </h4>
              ) : (
                <>
                  <br />
                </>
              )}
              {props.allergens.includes("nuts") ? (
                <h4 title="Nuts">
                  <NutsIcon
                    width={30}
                    height={30}
                    wrapperStyle={{ alignSelf: "center", margin: "-50% 0" }}
                    outerColor={"#f2a2c4"}
                  />
                </h4>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
