import "./styles/slushee.css";
import { useEffect, useState } from 'react'

export default function Flavour(props) {
  const [show, setShow] = useState(props.showMark)

  useEffect(() => {
    setShow(props.showMark)
  }, [props.showMark])

  const closeWindow = () => {
    // alert(show)
    if (show) {
      setShow(false)
      props.resetSlush();
    } else {
      props.windowClosed(props.name);
      props.changeColour(props.colour);
      setShow(true)
    }
  }

  return (
    <div onClick={() => closeWindow()} className="slushFlavour">
      <h4 style={{ backgroundColor: `${props.colour}`}}>{props.name}</h4>
      {props.currentName === props.name && show ?
        <div className="slushModal" style={{ backgroundColor: `${props.colour}` }}>
          <div className="slushHead">
            <h2 className="left">{props.name}</h2>
            <h2 onClick={() => closeWindow()} className="right">X</h2>
          </div>
          <hr />
          <div>
            <div className="slushHead">
              <div className="left"><h3>Description</h3>
                <p>{props.description}</p></div>
              <div className="right"><h3>Available At:</h3>
              {props.sites.autoport?<li>Autoport</li>:<></>}
              {props.sites.bearsden?<li>Bearsden</li>:<></>}
              {props.sites.braeside?<li>Braeside</li>:<></>}
              {props.sites.bridgeton?<li>Bridgeton</li>:<></>}
              {props.sites.crowwood?<li>Crowwood</li>:<></>}
              {props.sites["dumbarton rd"]?<li>Dumbarton Rd</li>:<></>}
              {props.sites["st james rd"]?<li>St James Rd</li>:<></>}
              </div>
            </div>
            {/* <h4>Description</h4>
            <p>{props.description}</p>
            <h4>Available At:</h4>
            <li>Test</li> */}
          </div>
          <hr/>
          <div className="infoIcons">
          {props.sugarfree ?
            <div>
            <span>SF</span>
            <p>Sugar Free</p>
          </div>
            : <></>}
          {props.vegan ?
            <div>
              <span>V</span>
              <p>Vegan</p>
            </div>
            : <></>}
          {props.sugarFree ?
            <div>
            <span>V</span>
            <p>Vegetarian</p>
          </div>
            : <></>}
          {props.carbonated ?
            <div>
            <span>C</span>
            <p>Carbonated</p>
          </div>
            : <></>}
            </div>
        </div>
        :
        <></>
      }
    </div>
  );
}
