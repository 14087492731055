import Nav from "./Nav";
import Footer from "./Footer";
import "./styles/application.css";
import { useState } from 'react';
import axios from "axios";

export default function Application() {
    const [store, setStore] = useState("");
    const [position, setPosition] = useState("");
    const [name, setName] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [postcode, setPostcode] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [about, setAbout] = useState("");
    const [cv, setCv] = useState("");
    const [message, setMessage] = useState("")
    
    document.title = "Tubbees | Apply";

    const send = async e => {
        setMessage("We are now sending your application. Please wait as this may take a couple of minutes.")
        const data = new FormData();
        var candidate = {
            store: store,
            position: position,
            name: name,
            address: address,
            city: city,
            postcode: postcode,
            email: email,
            phone: phone,
            about: about,
            cv: cv
        }
        const json = JSON.stringify(candidate);
        data.append('file', cv);
        data.append("candidate", json);
        await axios.post("https://nitrous.rt-itservices.co.uk/tubbeesSite/apply", data)
            .then(res => {
                setMessage("")
                alert("Application has been submitted")
                window.location.reload()
            }).catch(err => { console.log(err) })
    }

    return (
        <>
            <Nav />
            <section clasName="applicationPage">
                <div className="applicationForm">
                    <h2>Apply to work with us</h2>
                    <label>Preferred Store<span>*</span>:</label>
                    <select name="store" onChange={e => { setStore(e.target.value) }} required>
                        <option>--- Select Store ---</option>
                        <option>Bearsden Services</option>
                        <option>Dumbarton Rd</option>
                        <option>Paisley Rd West</option>
                        <option>Coatbridge</option>
                        <option>Crowwood</option>
                        <option>St James Rd</option>
                        <option>Sauchiehall St</option>
                        <option>Wishaw</option>
                    </select>
                    <label>Position<span>*</span>:</label>
                    <select onChange={e => { setPosition(e.target.value) }} required>
                        <option>--- Select Position ---</option>
                        <option>Dessert Lab Assistant</option>
                        <option>Dessert Lab Supervisor</option>
                        <option>Dessert Lab Manager</option>
                    </select>
                    <label>Full Name<span>*</span></label>
                    <input type="text" required onChange={e => { setName(e.target.value) }} className="formInput"/>
                    <label>Full Address<span>*</span></label>
                    <input type="text" required onChange={e => { setAddress(e.target.value) }} className="formInput"/>
                    <label>Postcode<span>*</span></label>
                    <input type="text" required onChange={e => { setPostcode(e.target.value) }} className="formInput"/>
                    <label>City<span>*</span></label>
                    <input type="text" required onChange={e => { setCity(e.target.value) }} className="formInput"/>
                    <label>E-mail <span>*</span></label>
                    <input type="email" required onChange={e => { setEmail(e.target.value) }} className="formInput"/>
                    <label>Phone <span>*</span></label>
                    <input type="number" required onChange={e => { setPhone(e.target.value) }} className="formInput"/>
                    <label>Why do you think you would like to work for Tubbees <span>*</span></label>
                    <textarea type="text" cols="40" rows="5" onChange={e => { setAbout(e.target.value) }} />
                    <label>Upload your CV on PDF or WORD<span>*</span></label>
                    <input type="file" name="cv" required accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" onChange={e => { setCv(e.target.files[0]) }} className="formInput"/>
                    <button onClick={() => send()}>Submit</button>
                    <br />
                    <h3>{message}</h3>

                </div>
            </section>
            <Footer />
        </>
    )
}