import "./styles/Home.css";
// import "../test/home.css";
import Bounce from "react-reveal/Bounce";
import churrosLeft from "../images/churros.webp";
import oreoLeft from "../images/oreo_left.webp";
import oreoRight from "../images/oreoRight.webp";
import slushRight from "../images/slush.webp";
import { useMediaQuery } from "react-responsive";
import { useEffect,useState } from "react";

export default function Welcome() {
  const [mobile,setMobile] = useState(false)
  const smallScreen = useMediaQuery({ query: "(max-width: 700px)" });
  const otherScreen = useMediaQuery({ query: "(min-width: 701px)" });
useEffect(()=>{
  if(smallScreen){
    setMobile(true)
  }else{
    setMobile(false)
  }
},[])

  return (
    <section className="welcome">
      <div className="welcomeImg">
        <Bounce left cascade>
          <div id="left">
            <img className="leftImg1" src={churrosLeft} alt="churros" />
            <img className="leftImg2" src={oreoLeft} alt="oreo" />
          </div>
        </Bounce>
        <Bounce right cascade>
          <div id="right">
            <img className="rightImg1" src={slushRight} alt="slush" />
            <img className="rightImg2" src={oreoRight} alt="oreo" />
          </div>
        </Bounce>
      </div>
      <h2 id="welcome">Welcome To{mobile?<><br/></>:<></>} Tubbees Dessert Lab</h2>
      <p>
        We have everything you could dream of, from{" "}
        <span>up to 90+ flavours of slushee,</span> Nitrogen ice cream &amp; a
        lab full of tasty desserts &amp; treats! We also specialize in stocking
        the newest, craziest &amp; coolest import goods for you to try! With our
        lab always working on new concoctions, &amp; therefore will always bring
        the best, newest desserts for you to try and enjoy!
      </p>
    </section>
  );
}
