import "./scssStyles/menu.scss";
import { useState } from "react";
import logo from "../images/tubbees-logo.png";
import { MilkIcon,EggIcon,GlutenIcon,NutsIcon } from "react-allergens";
import FlipCameraAndroidIcon from "@mui/icons-material/FlipCameraAndroid";
import './styles/menu1.css'

export default function MenuItem(props) {
    const [cardFlip, setCardFlip] = useState("");

    function flip() {
        if (cardFlip === "") {
            setCardFlip("flip");
        } else {
            setCardFlip("");
        }
    }

    return (
        <>
            <div className={`card ${cardFlip}`} onClick={() => flip()}>
                <div className="face front glowing" style={{ backgroundImage: `url(${props.img})` }}>
                    <img className="logo" src={logo} alt="Tubbees logo" />
                    <h2>{props.name}</h2>
                    <span>
                        <FlipCameraAndroidIcon
                            sx={{ color: "fff" }}
                            fontSize="large"
                            id="test"
                        />
                    </span>
                    <div className="storeIcons">
                        {props.sites?props.sites.map(function (d, i) {
                            return (
                                <div key={i}>
                                    {d.available ? (
                                        <span title={`${d.name}`} style={{ borderRadius: "50%", border: "1px solid black", backgroundColor: `${d.colour}`}}>==</span>
                                    ) : (
                                        <></>
                                    )
                                    }
                                </div>
                            )
                        }):<></>}
                    </div>
                </div>
                <div className="face back">
                    <h6>{props.name}</h6>
                    <h5>What do I have?</h5>
                    <div>
                        {props.description.map(function (dd, i) {
                            return (
                                <li key={i}>{dd}</li>
                            )
                        })}
                    </div>
                    <div className="bott">
                        <div className="cals">
                            <p>kcal</p>
                            <h4>{props.calories}</h4>
                            <div className="perct">
                                <h4>{props.percentage}</h4>
                            </div>
                        </div>
                        <div className="aller">
                            <p>Contains</p>
                            <h4>Allergens</h4>
                            <div className="perct">
                                {props.allergens.includes("milk") ?
                                    <h4 title="Milk">
                                        <MilkIcon
                                            width={30}
                                            height={30}
                                            wrapperStyle={{ alignSelf: "center", margin: "-50% 0" }}
                                            outerColor={"#f2a2c4"}
                                        />
                                    </h4>
                                    : <><br/></>}
                                {props.allergens.includes("egg") ?
                                    <h4 title="Egg">
                                        <EggIcon
                                            width={30}
                                            height={30}
                                            wrapperStyle={{ alignSelf: "center", margin: "-50% 0" }}
                                            outerColor={"#f2a2c4"}
                                        />
                                    </h4>
                                    : <><br/></>}
                                {props.allergens.includes("wheat") ?
                                    <h4 title="Wheat">
                                        <GlutenIcon
                                            width={30}
                                            height={30}
                                            wrapperStyle={{ alignSelf: "center", margin: "-50% 0" }}
                                            outerColor={"#f2a2c4"}
                                        />
                                    </h4>
                                    : <><br/></>}
                                    {props.allergens.includes("nuts") ?
                                    <h4 title="Nuts">
                                        <NutsIcon
                                            width={30}
                                            height={30}
                                            wrapperStyle={{ alignSelf: "center", margin: "-50% 0" }}
                                            outerColor={"#f2a2c4"}
                                        />
                                    </h4>
                                    : <></>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}