import React, { useEffect, useState } from "react";
import { Marker, InfoWindow } from "@react-google-maps/api";
import MapMarker from "../images/mapMarker.webp";
import "./styles/storeMap.css";
import RoomIcon from "@mui/icons-material/Room";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Slush from "../images/icon/slushCupBlack.webp";
import Dessert from "../images/icon/pancakesBlack.webp";
import IceCream from "../images/icon/iceCreamBlack.webp";
import Candy from "../images/icon/candyBlack.webp";
import pancakes from "../images/icon/pancakesBlack.webp";

export default function StoreMarker(props) {
  const [show, setShow] = useState(props.showMark);

  useEffect(() => {
    setShow(props.showMark);
    // console.log(props.comingSoon);
  }, [props.showMark]);

  const showInfo = () => {
    setShow(!show);
  };
  const closeInfo = () => {
    setShow(false);
    props.resetMap();
  };

  const closeWindow = () => {
    props.windowClosed(props.name);
  };

  return (
    <div>
      <Marker
        icon={MapMarker}
        position={{ lat: props.marker.latitude, lng: props.marker.longitude }}
        onClick={closeWindow}
      />
      {props.currentName === props.name ? (
        <InfoWindow
          onCloseClick={closeInfo}
          position={{ lat: props.marker.latitude, lng: props.marker.longitude }}
        >
          {props.comingSoon === true ? (
            <>
              <div className="marker info">
                <h4 style={{ margin: "3% auto" }}>Opening Soon </h4><br />
                <h4>{props.name}</h4>
              </div>
              <div className="marker info">
                <p>
                  <b>{props.marker.addressLine1}</b>
                </p>
                <p>
                  <b>{props.marker.addressLine2}</b>
                </p>
                <p>
                  <b>{props.marker.addressLine3}</b>
                </p>
                <p>
                  <b>{props.marker.postcode}</b>
                </p>
              </div>
            </>
          ) : (
            <>
              <div className="marker info">
                <h4>{props.name}</h4>
                {props.utilities.hasSlush ? (
                  <div className="foodIcon">
                    <img src={Slush} alt="Slush" title="Slush" />
                  </div>
                ) : (
                  <></>
                )}
                {props.utilities.hasDesserts ? (
                  <div className="foodIcon">
                    <img src={Dessert} alt="Dessert" title="Desserts" />
                  </div>
                ) : (
                  <></>
                )}
                {props.utilities.hasIceCream ? (
                  <div className="foodIcon">
                    <img src={IceCream} alt="Ice Cream" title="Ice Cream" />
                  </div>
                ) : (
                  <></>
                )}
                {props.utilities.hasCandy ? (
                  <div className="foodIcon">
                    <img src={Candy} alt="Candy" title="Candy" />
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="marker info">
                <div>
                  <RoomIcon color="#FFF" fontSize="medium" className="icon" />
                </div>
                <a
                  className="link"
                  href={`https://maps.google.com/?q=${props.marker.addressLine1}+${props.marker.postcode}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div>
                    <p>
                      <b>{props.marker.addressLine1}</b>
                    </p>
                    <p>
                      <b>{props.marker.addressLine2}</b>
                    </p>
                    <p>
                      <b>{props.marker.addressLine3}</b>
                    </p>
                    <p>
                      <b>{props.marker.postcode}</b>
                    </p>
                  </div>
                </a>
                <div className="info2">
                  <div>
                    <LocalPhoneIcon
                      color="#FFF"
                      fontSize="medium"
                      className="icon"
                    />
                    <span>
                      <b>{props.contact.phoneNumber}</b>
                    </span>
                  </div>
                  <div>
                    <AccessTimeIcon
                      color="#FFF"
                      fontSize="medium"
                      className="icon"
                      style={{ marginTop: "8%" }}
                    />
                    <br />
                    {props.opening === "mon_thurs" ? (
                      <>
                        <p className="day">
                          MON - THURS
                          <br />
                          <b>
                            {props.hours.Mon_Thurs.opens} -{" "}
                            {props.hours.Mon_Thurs.closes}
                          </b>
                        </p>
                        <br />
                        <p className="day">
                          FRI - SUN
                          <br />
                          <b>
                            {props.hours.Fri_Sun.opens} -{" "}
                            {props.hours.Fri_Sun.closes}
                          </b>
                        </p>
                      </>
                    ) : props.opening === "everyday" ? (
                      <>
                        <p className="day">
                          MON - SUN
                          <br />
                          <b className="day">24/7</b>
                        </p>
                      </>
                    ) : props.opening === "sun_thurs" ? (
                      <>
                        <p className="day">
                          SUN - THUR
                          <br />
                          <b className="day">{props.hours.Sun_Thurs.opens} -{" "}
                            {props.hours.Sun_Thurs.closes}</b>
                        </p>
                        <br />
                        <p className="day">
                          FRI - SAT
                          <br />
                          <b>
                            {props.hours.Fri_Sat.opens} -{" "}
                            {props.hours.Fri_Sat.closes}
                          </b>
                        </p>
                      </>
                    ) : (
                      <>
                        <p className="day">
                          MON - SAT
                          <br />
                          <b>
                            {props.hours.Mon_Sat.opens} -{" "}
                            {props.hours.Mon_Sat.closes}
                          </b>
                        </p>
                        <br />
                        <p className="day">
                          SUN
                          <br />
                          <b>
                            {props.hours.Sun.opens} - {props.hours.Sun.closes}
                          </b>
                        </p>
                      </>
                    )}
                  </div>
                </div>
                <div className="info3">
                  <img
                    src={pancakes}
                    alt="pancakes"
                    width="10%"
                    className="infoImg"
                  />
                  <p>
                    Hot Desserts open: <b>{props.utilities.DessertLab.opens}</b>
                  </p>
                </div>
              </div>
            </>
          )}
        </InfoWindow>
      ) : (
        <></>
      )}
    </div>
  );
}
