import "./styles/Home.css";
// import "../test/home.css";
import Bounce from "react-reveal/Bounce";
import churrosLeft from "../images/churros.webp";
import oreoLeft from "../images/oreo_left.webp";
import oreoRight from "../images/oreoRight.webp";
import slushRight from "../images/slush.webp";
import { useMediaQuery } from "react-responsive";
import { useEffect, useState } from "react";
import Elf from "./xmas/images/ELF.png";
import Santa from "./xmas/images/SANTA.png";
import Snowy from "./xmas/images/SNOWY.png";
import Tree from "./xmas/images/TREE.png";

export default function Welcome() {
    const [mobile, setMobile] = useState(false);
    const smallScreen = useMediaQuery({ query: "(max-width: 700px)" });
    const otherScreen = useMediaQuery({ query: "(min-width: 701px)" });
    useEffect(() => {
        if (smallScreen) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    }, []);

    return (
        <section className="welcome">
            <div className="welcomeImg">
                <Bounce left cascade>
                    <div id="left">
                        <img className="leftImg1" src={Elf} alt="elf" />
                        <img className="leftImg2" src={Tree} alt="tree" />
                    </div>
                </Bounce>
                <Bounce right cascade>
                    <div id="right">
                        <img className="rightImg1" src={Santa} alt="santa" />
                        <img className="rightImg2" src={Snowy} alt="snowy" />
                    </div>
                </Bounce>
            </div>
            <h2 id="welcome">
                Welcome To
                {mobile ? (
                    <>
                        <br />
                    </>
                ) : (
                    <></>
                )}{" "}
                Tubbees Dessert Lab
            </h2>
            <p>
                We have everything you could dream of, from{" "}
                <span>up to 90+ flavours of slushee,</span> Nitrogen ice cream
                &amp; a lab full of tasty desserts &amp; treats! We also
                specialize in stocking the newest, craziest &amp; coolest import
                goods for you to try! With our lab always working on new
                concoctions, &amp; therefore will always bring the best, newest
                desserts for you to try and enjoy!
            </p>
        </section>
    );
}
