import "./styles/Home.css";
import iceCream from "../images/icon/Cone.svg";
import Slush from "../images/icon/Slush.svg";
import Muffin from "../images/icon/Cupcake.svg";
import Pancakes from "../images/icon/Pancake.svg";
import { useNavigate } from "react-router-dom";


export default function MenuBar() {
  const navigate = useNavigate();

  return (
    <section className="MenuBar">
      <img src={Slush} alt="slushee" className="shake-slow" />
      <img src={Pancakes} alt="pancakes" className="shake-slow" />
      <img src={Muffin} alt="Muffin" className="shake-slow" />
      <img src={iceCream} alt="ice cream" className="shake-slow" />
      <button>Full Menu Coming Soon</button>
      <img src={Slush} alt="slushee" className="shake-slow" />
      <img src={Pancakes} alt="pancakes" className="shake-slow" />
      <img src={Muffin} alt="Muffin" className="shake-slow" />
      <img src={iceCream} alt="ice cream" className="shake-slow" />
    </section>
  );
}
