import "../menu.scss";
import { useEffect, useState } from "react";
import IndvCat from "./IndvCat";
import axios from 'axios'

export default function Cats(props) {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    axios
      .get(
        `https://nitrous.rt-itservices.co.uk/tubbeesSite/menu?category=${props.category}&child=${props.child}`
      )
      .then((res) => {
        setProducts(res.data);
        console.log(res.data)
      })
      .catch((err) => {
        console.log(err);
      });
    // axios.get("https://nitrous.rt-itservices.co.uk/tubbeesSite/stores").then((res) => {
    //   setStores(res.data)
    // }).catch((err) => {
    //   console.log(err)
    // })
  }, []);

  return (
    <section className="dessert_category">
      {products.map(function (d, i) {
        return (
          <IndvCat
            key={i}
            name={d.name}
            img={d.img}
            description={d.description}
            calories={d.calories}
            percentage={d.percentage}
            allergens={d.allergens}
            // sites={d.sites}
          />
        );
      })}
    </section>
  );
}
