import './menu.scss';
import Nav from '../Nav';
import Footer from '../Footer';
import SideMenu from './SideMenu';
import Desserts from '../Desserts';

export default function NewMenu(){
    document.title = "Tubbees | Menu - Hot Desserts";

    return(
        <main>
          <Nav />
          {/* <h1>Check our dessert menu</h1> */}
          <section className="menu">
            <aside>
                <SideMenu/>
            </aside>
            <div className="menuContent">
               <h2>Check Our Menu</h2> 
               <div className="row">
                <h2>Some text of images of the latest desserts?</h2>
               </div>
            </div>
          </section>
          <Footer />  
        </main>
    )
}