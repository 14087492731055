import './styles/booktable.css';


export default function BookTable() {
  return (
    <section className="bookTable">

      <h2 >When to book a table?</h2>

      <div className="bookInfo">

      <p >
        Wondering if you need to book a table before visiting us? We've got all
        the information you need.
      </p>

      <p >
        → If you are visiting us for Take Away there is NO need to book a table.
        You are welcome to come in during open hours and choose your treats.
      </p>

      <p >
        → If you are visiting us for Sit In, then you will need to book a table.
        That way we will ensure that when you come we have a place to sit and
        enjoy your treats.
      </p>
      </div>
      <iframe
        width="100%"
        height="550px"
        src="https://book.appointedd.com/app/5f27f831e721074a69118ebe"
      ></iframe>
    </section>
  );
}
